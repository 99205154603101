// IMPORTS
import {
    Dispatch,
    SetStateAction,
    useContext,
    useEffect,
    useState
} from 'react';
import {
    Grid,
    TextField,
    ToggleButton,
    ToggleButtonGroup
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en-gb';
import { SiteContext } from '../../../../main/Main';
import dayjs from 'dayjs';
// COMPONENTS
import PrintButtons from './PrintButtons';
import Paper from '../../../global/Paper';
import TotalsForm from './TotalsForm';
import GetAllJobCodes from '../../../global/databaseLogic/GetAllJobCodes';
import RoleCheck from '../../../global/RoleCheck';
import AutocompleteMultiple from '../../../global/AutocompleteMultiple';
// LOGIC
import { ServiceBalance } from '../logic/GetTotals';
// INTERFACES
import {
    CustomerVehicle,
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { Order } from '../../../global/interfaces/PartsInterface';
import { Customer, Site } from '../../../global/interfaces/GeneralInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';

interface DetailsProps {
    id: number;
    readOnly: boolean;
    serviceDetails: Service;
    setServiceDetails: Dispatch<SetStateAction<Service>>;
    sublets: PurchaseOrder[];
    partSales: Order[];
    selectedCustomer: Customer;
    selectedInsurer: Customer;
    selectedVehicle: Vehicle | CustomerVehicle;
    vehicleType: string;
}

const Details = ({
    id,
    readOnly,
    serviceDetails,
    setServiceDetails,
    sublets,
    partSales,
    selectedCustomer,
    selectedInsurer,
    selectedVehicle,
    vehicleType
}: DetailsProps) => {
    const SiteId = parseInt(localStorage.getItem('SiteId'));
    const siteDetails = useContext<Site>(SiteContext);
    const [jobCodes, setJobCodes] = useState([]);

    useEffect(() => {
        GetAllJobCodes(setJobCodes, SiteId);
    }, []);

    const handleChangeType = (
        event: React.MouseEvent<HTMLElement>,
        newType: string | null
    ) => {
        if (newType != null) {
            setServiceDetails({ ...serviceDetails, serviceComplete: newType });
        }
    };

    // Check if the service has been fully paid, if it has then it can be changed to finalised
    // but if it has not then it can not be finalised
    const finalisedDisabled = () => {
        let balance = ServiceBalance(serviceDetails);

        if (
            (balance > 0.01 &&
                (serviceDetails.jobType === 'Retail' ||
                    selectedCustomer?.id)) ||
            !id
        ) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={9} textAlign="center">
                    <Paper>
                        <ToggleButtonGroup
                            color="primary"
                            exclusive
                            fullWidth
                            value={serviceDetails.serviceComplete}
                            onChange={handleChangeType}
                            disabled={readOnly}
                            size="small"
                        >
                            <ToggleButton value="Incomplete">
                                Incomplete
                            </ToggleButton>
                            <ToggleButton value="Complete">
                                Complete
                            </ToggleButton>
                            <ToggleButton
                                value="Finalised"
                                disabled={finalisedDisabled()}
                            >
                                Finalised
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Paper>
                    <br />
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    disabled={readOnly}
                                    size="small"
                                    label="Next Service Due"
                                    InputLabelProps={{ shrink: true }}
                                    value={serviceDetails.nextService}
                                    onChange={(e) =>
                                        setServiceDetails({
                                            ...serviceDetails,
                                            nextService: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                            {siteDetails.vehicleTypeSold === 'Motorcycles' ? (
                                <Grid item xs={4}>
                                    <TextField
                                        disabled={readOnly}
                                        size="small"
                                        fullWidth
                                        label="Insurance Claim Number"
                                        InputLabelProps={{ shrink: true }}
                                        value={
                                            serviceDetails.insuranceClaimNumber
                                        }
                                        onChange={(e) =>
                                            setServiceDetails({
                                                ...serviceDetails,
                                                insuranceClaimNumber:
                                                    e.target.value
                                            })
                                        }
                                    />
                                </Grid>
                            ) : null}

                            <Grid item xs={4}>
                                <AutocompleteMultiple
                                    options={jobCodes}
                                    useTwoOptionLabels={false}
                                    primaryOptionLabel="serviceCode"
                                    textfieldLabel="Job Code"
                                    isDisabled={readOnly}
                                    selectedValues={serviceDetails.jobCodes}
                                    handleSelectedValueChange={(newValue) => {
                                        let newJobCodes = [];
                                        let newInvoiceDescription = '';
                                        let newJobDescription = '';
                                        let newJobFinish = dayjs(
                                            serviceDetails.jobFinish
                                        );

                                        newValue.forEach((value, index) => {
                                            newJobCodes.push(value);
                                            if (index === 0) {
                                                newInvoiceDescription =
                                                    newInvoiceDescription +
                                                    value.invoiceDescription;
                                                newJobDescription =
                                                    newJobDescription +
                                                    value.jobCardDescription;

                                                newJobFinish = dayjs(
                                                    serviceDetails.jobStart
                                                )
                                                    .clone()
                                                    .add(
                                                        value.serviceDuration,
                                                        'hour'
                                                    );
                                            } else {
                                                newInvoiceDescription =
                                                    newInvoiceDescription +
                                                    '\n' +
                                                    value.invoiceDescription;
                                                newJobDescription =
                                                    newJobDescription +
                                                    '\n' +
                                                    value.jobCardDescription;
                                            }
                                        });

                                        setServiceDetails({
                                            ...serviceDetails,
                                            jobCodes: newJobCodes,
                                            invoiceDescription:
                                                newInvoiceDescription,
                                            jobDescription: newJobDescription,
                                            jobFinish:
                                                newJobFinish.format(
                                                    'YYYY-MM-DDTHH:mm'
                                                )
                                        });
                                    }}
                                    handleInputValueChange={() => null}
                                />
                            </Grid>
                            {siteDetails.vehicleTypeSold === 'Bicycles' ? (
                                <Grid item xs={4}></Grid>
                            ) : null}
                            <Grid item xs={4}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="datetime-local"
                                    label="Start Date & Time"
                                    variant="outlined"
                                    value={serviceDetails?.jobStart}
                                    InputLabelProps={{ shrink: true }}
                                    onChange={(e) =>
                                        setServiceDetails({
                                            ...serviceDetails,
                                            jobStart: e.target.value
                                        })
                                    }
                                    disabled={readOnly}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="datetime-local"
                                        label="Finish Date & Time"
                                        variant="outlined"
                                        value={serviceDetails?.jobFinish}
                                        InputLabelProps={{ shrink: true }}
                                        onChange={(e) =>
                                            setServiceDetails({
                                                ...serviceDetails,
                                                jobFinish: e.target.value
                                            })
                                        }
                                        disabled={readOnly}
                                    />
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Paper>
                    <br />
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    disabled={readOnly}
                                    fullWidth
                                    multiline
                                    rows={10}
                                    label="Job Card Description"
                                    InputLabelProps={{ shrink: true }}
                                    value={serviceDetails.jobDescription}
                                    onChange={(e) =>
                                        setServiceDetails({
                                            ...serviceDetails,
                                            jobDescription: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    disabled={readOnly}
                                    multiline
                                    rows={10}
                                    label="Customer Invoice Description"
                                    InputLabelProps={{ shrink: true }}
                                    value={serviceDetails.invoiceDescription}
                                    onChange={(e) =>
                                        setServiceDetails({
                                            ...serviceDetails,
                                            invoiceDescription: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={readOnly}
                                    fullWidth
                                    multiline
                                    rows={2}
                                    label="Recommendations / Notes"
                                    InputLabelProps={{ shrink: true }}
                                    value={serviceDetails.recommendations}
                                    onChange={(e) =>
                                        setServiceDetails({
                                            ...serviceDetails,
                                            recommendations: e.target.value
                                        })
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    {serviceDetails.serviceComplete === 'Finalised' ? (
                        <>
                            <Paper>
                                <RoleCheck
                                    permission="edit_finalisedDateService"
                                    component={
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="en-gb"
                                        >
                                            <DatePicker
                                                disabled={readOnly}
                                                format="DD/MM/YYYY"
                                                label="Date Paid"
                                                value={dayjs(
                                                    serviceDetails?.finalisedDate
                                                )}
                                                onChange={(newValue) =>
                                                    setServiceDetails({
                                                        ...serviceDetails,
                                                        finalisedDate:
                                                            dayjs(
                                                                newValue
                                                            ).toISOString()
                                                    })
                                                }
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: false,
                                                        size: 'small',
                                                        InputLabelProps: {
                                                            shrink: true
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    }
                                    nullComponent={
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                            adapterLocale="en-gb"
                                        >
                                            <DatePicker
                                                disabled
                                                format="DD/MM/YYYY"
                                                label="Finalised Date"
                                                value={dayjs(
                                                    serviceDetails?.finalisedDate
                                                )}
                                                onChange={(newValue) =>
                                                    setServiceDetails({
                                                        ...serviceDetails,
                                                        finalisedDate:
                                                            dayjs(
                                                                newValue
                                                            ).toISOString()
                                                    })
                                                }
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: false,
                                                        size: 'small',
                                                        InputLabelProps: {
                                                            shrink: true
                                                        }
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    }
                                />
                            </Paper>
                            <br />
                        </>
                    ) : null}

                    <TotalsForm
                        serviceDetails={serviceDetails}
                        sublets={sublets}
                        partSales={partSales}
                    />
                    <br />
                    {readOnly && (
                        <PrintButtons
                            serviceDetails={serviceDetails}
                            selectedCustomer={selectedCustomer}
                            vehicleType={vehicleType}
                            selectedVehicle={selectedVehicle}
                            partSales={partSales}
                            selectedInsurer={selectedInsurer}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default Details;

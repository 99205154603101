import api from '../../../../../api';

interface GetServicePresetPartsProps {
    jobCodeIds: number[];
    SiteId: number;
}

const GetServicePresetParts = async ({
    jobCodeIds,
    SiteId
}: GetServicePresetPartsProps) => {
    const queryParams = jobCodeIds.map((code) => `jobCodes=${code}`).join('&');

    const res = await api.get(`presetPartsGrouped/${SiteId}/?${queryParams}`);

    return res.data;
};

export default GetServicePresetParts;

import { Dispatch, SetStateAction } from 'react';
import { Typography } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment';

// COMPONENT
import DataCell from '../../tableComponents/DataCell';
import RoleCheck from '../../RoleCheck';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// LOGIC
import { DateFormatter } from '../../logic/Formatters';
import QuickSaleUpdate from '../../../parts/newSale/logic/updateFunctions/QuickSaleUpdate';

// INTERFACE
import { PaymentLine } from '../../interfaces/GeneralInterface';
import { Order } from '../../interfaces/PartsInterface';
import { VehicleSale } from '../../interfaces/VehicleSaleInterface';
import { Service } from '../../interfaces/ServiceInterface';
import { showSnackbar } from '../../interfaces/GlobalInterface';

interface PaymentDateProps {
    payment: PaymentLine;
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    saleDetails: Order;
    oldSale: Order;
    vehicleSaleDetails: VehicleSale;
    serviceDetails: Service;
    showSnackbar: showSnackbar;
    disabled: boolean;
    isPaymentTillReconciled: boolean;
    isFinalised: boolean;
}

const PaymentDate = ({
    payment,
    paymentLines,
    setPaymentLines,
    saleDetails,
    oldSale,
    vehicleSaleDetails,
    serviceDetails,
    showSnackbar,
    disabled,
    isPaymentTillReconciled,
    isFinalised
}: PaymentDateProps) => {
    // Update payment date
    const updatePaymentDate = (id: number, value: Dayjs) => {
        var index = paymentLines.findIndex((x) => x.id === id);
        let rows = [...paymentLines];
        let row = { ...rows[index] };

        row.date = value.format('YYYY-MM-DD');
        row.timestamp =
            value.format('YYYY-MM-DD') + 'T' + row.timestamp.split('T')[1];
        rows[index] = row;
        setPaymentLines(rows);
    };

    return (
        <>
            <DataCell>
                {payment.type === 'account' ? (
                    saleDetails?.id ? (
                        saleDetails?.finalisedAt ? (
                            DateFormatter(saleDetails.finalisedAt)
                        ) : (
                            'N/A until this order is finalised'
                        )
                    ) : serviceDetails?.finalisedDate ? (
                        DateFormatter(serviceDetails.finalisedDate)
                    ) : (
                        'N/A until this service is finalised'
                    )
                ) : payment?.removed ? (
                    <>
                        {DateFormatter(String(payment?.removedOn))}
                        <br />
                        <span
                            style={{ color: 'grey' }}
                        >{`Removed By: ${payment?.removedBy}`}</span>
                    </>
                ) : payment.reconciled ||
                  payment.inactive ||
                  payment.removed ? (
                    `${moment(payment.date).format('DD/MM/YYYY')} ${moment(payment.timestamp).format('h:mm A')}`
                ) : isPaymentTillReconciled ? (
                    <RoleCheck
                        permission="admin_manager"
                        component={
                            <>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    <DatePicker
                                        disabled={disabled}
                                        value={dayjs(payment?.date)}
                                        onChange={(newValue) =>
                                            updatePaymentDate(
                                                payment.id,
                                                newValue
                                            )
                                        }
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                error: false,
                                                size: 'small',
                                                InputLabelProps: {
                                                    shrink: true
                                                },
                                                inputProps: {
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                                <br />
                                {moment(payment.timestamp).format('h:mm A')}
                            </>
                        }
                        nullComponent={
                            <Typography
                                variant="body2"
                                sx={{
                                    color: payment.inactive ? '#808080' : ''
                                }}
                            >
                                {DateFormatter(String(payment?.date))}
                            </Typography>
                        }
                    />
                ) : isFinalised ? (
                    <RoleCheck
                        permission="edit_salePayment"
                        component={
                            <>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale="en-gb"
                                >
                                    <DatePicker
                                        disabled={disabled}
                                        value={dayjs(payment?.date)}
                                        onChange={(newValue) =>
                                            updatePaymentDate(
                                                payment.id,
                                                newValue
                                            )
                                        }
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                                error: false,
                                                size: 'small',
                                                InputLabelProps: {
                                                    shrink: true
                                                },
                                                inputProps: {
                                                    style: {
                                                        textAlign: 'center'
                                                    }
                                                }
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                                <br />
                                {moment(payment.timestamp).format('h:mm A')}
                            </>
                        }
                        nullComponent={
                            <Typography
                                variant="body2"
                                sx={{
                                    color: payment.inactive ? '#808080' : ''
                                }}
                            >
                                {DateFormatter(String(payment?.date))}
                            </Typography>
                        }
                    />
                ) : (
                    <>
                        <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale="en-gb"
                        >
                            <DatePicker
                                disabled={disabled}
                                value={dayjs(payment?.date)}
                                onChange={(newValue) =>
                                    updatePaymentDate(payment.id, newValue)
                                }
                                slotProps={{
                                    textField: {
                                        variant: 'standard',
                                        error: false,
                                        size: 'small',
                                        InputLabelProps: {
                                            shrink: true
                                        },
                                        inputProps: {
                                            style: {
                                                textAlign: 'center'
                                            }
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                        <br />
                        {moment(payment.timestamp).format('h:mm A')}
                    </>
                )}
            </DataCell>
        </>
    );
};

export default PaymentDate;
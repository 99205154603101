const HandleAddPresetPart = (
    selectedPart,
    supplierData,
    serviceCode,
    setServiceCode
) => {
    // The unit and stock data sent from the part search component
    let unitData = selectedPart.unitData;
    let currentParts = serviceCode.presetParts ? serviceCode.presetParts : [];

    // Calculate the new row id
    // If there is no orderlines simply set it to 0
    // Otherwise set it to the last id in the orderlines plus 1
    let rowId: number;
    if (currentParts.length > 0) {
        rowId = currentParts.at(-1)['id'] + 1;
    } else {
        rowId = 0;
    }

    let newRow = {
        id: rowId,
        SupplierId: Number(supplierData.SupplierId),
        UnitId: unitData.id,
        partNumber: unitData.partNumber,
        name: unitData.name,
        quantity: 1
    };

    currentParts.push(newRow);

    setServiceCode({ ...serviceCode, presetParts: currentParts });
};

export default HandleAddPresetPart;

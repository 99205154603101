import { DebtorPayment } from '../../../../../global/interfaces/GeneralInterface';

const CalculateDebtorPaymentBalanceSheet = (
    debtorPaymentData: { endDate: string; data: [number, DebtorPayment[]] }[]
) => {
    let debtorPaymentTotalPerSite = [];
    let debtorPaymentDataPerSite = [];

    for (let period of debtorPaymentData) {
        for (let [index, site] of period.data.entries()) {
            if (debtorPaymentTotalPerSite.length === index) {
                debtorPaymentTotalPerSite.push(site[0]);
                debtorPaymentDataPerSite.push(site[1]);
            } else {
                debtorPaymentTotalPerSite[index] += site[0];
                debtorPaymentDataPerSite[index] = debtorPaymentDataPerSite[
                    index
                ].concat(site[1]);
                debtorPaymentDataPerSite[index] = debtorPaymentDataPerSite[
                    index
                ].sort((a, b) => a.id - b.id);
            }
        }
    }

    return [debtorPaymentTotalPerSite, debtorPaymentDataPerSite];
};
export default CalculateDebtorPaymentBalanceSheet;

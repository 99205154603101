import { Dispatch, SetStateAction, useState } from 'react';
import {
    Typography,
    IconButton,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import { Clear, Lock, PlaylistAddCheck } from '@mui/icons-material';
import moment from 'moment';

// COMPONENTS
import DataCell from '../../tableComponents/DataCell';
import RoleCheck from '../../RoleCheck';

// LOGIC
import UnreconcileTransaction from './UnreconcileTransaction';
import GetReconciliationDetails from './GetReconciliationDetails';
import { DateFormatter } from '../../logic/Formatters';

// INTERFACES
import { PaymentLine, User } from '../../interfaces/GeneralInterface';
import { Order } from '../../interfaces/PartsInterface';
import { VehicleSale } from '../../interfaces/VehicleSaleInterface';
import { Service } from '../../interfaces/ServiceInterface';
import { showSnackbar } from '../../interfaces/GlobalInterface';

interface RemoveLineProps {
    payment: PaymentLine;
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    currentUser: User;
    saleDetails: Order;
    vehicleSaleDetails: VehicleSale;
    serviceDetails: Service;
    showSnackbar: showSnackbar;
    showTillReconciled: boolean;
    disabled: boolean;
    isPaymentTillReconciled: boolean;
    isFinalised: boolean;
}
const RemoveLine = ({
    saleDetails,
    vehicleSaleDetails,
    serviceDetails,
    payment,
    showTillReconciled,
    paymentLines,
    setPaymentLines,
    currentUser,
    showSnackbar,
    disabled,
    isPaymentTillReconciled,
    isFinalised
}: RemoveLineProps) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [reconciliationDetails, setReconciliationDetails] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    // Check if a payment line was added that day, if it was it can be removed otherwise it cannot
    const isRemovable = (row: PaymentLine) => {
        if (row.type === 'directDeposit') return true;
        if (row.removed) return false;
        if (new Date(row.date).toDateString() !== new Date().toDateString())
            return false;
        return true;
    };

    // Handle removing a payment line
    const handleRemove = (id: number) => {
        let tempPaymentLines = paymentLines;

        let removedPayment = tempPaymentLines.find((x) => x.id === id);

        removedPayment.removed = true;
        removedPayment.removedBy =
            currentUser?.firstName + ' ' + currentUser?.lastName;
        removedPayment.removedOn = new Date();

        // update tempPaymentLines with the removed payment
        tempPaymentLines = tempPaymentLines.map((x) =>
            x.id === id ? removedPayment : x
        );

        setPaymentLines([...tempPaymentLines]);
    };

    const handleOpenDialog = (payment) => {
        GetReconciliationDetails(
            saleDetails,
            vehicleSaleDetails,
            serviceDetails,
            payment.BankReconciliationId,
            setReconciliationDetails
        );
        setDrawerOpen(true);
    };

    return (
        <>
            <DataCell>
                {payment.amount < 0 && payment.creditId ? (
                    <Typography variant="caption" sx={{ color: '#808080' }}>
                        Credit ID:{' '}
                        <Typography
                            variant="caption"
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                                window.open(
                                    `/inventory/credit/${saleDetails.id}`,
                                    '_blank'
                                )
                            }
                        >
                            {saleDetails.id}
                        </Typography>
                    </Typography>
                ) : payment.inactive ? (
                    <Typography variant="caption" sx={{ color: '#808080' }}>
                        Sale ID:{' '}
                        <Typography
                            variant="caption"
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                                window.open(
                                    `/inventory/viewSale/${payment.linkedSaleId}`,
                                    '_blank'
                                )
                            }
                        >
                            {payment.linkedSaleId}
                        </Typography>
                    </Typography>
                ) : payment.reconciled ? (
                    <Tooltip
                        title="This payment has been bank reconciled, it cannot be edited."
                        placement="top"
                    >
                        <IconButton onClick={() => handleOpenDialog(payment)}>
                            <PlaylistAddCheck />
                        </IconButton>
                    </Tooltip>
                ) : showTillReconciled ? (
                    <RoleCheck
                        permission="admin_manager"
                        component={
                            <Tooltip
                                title="This payment has already been till reconciled. Please edit with caution."
                                placement="top"
                            >
                                <Lock />
                            </Tooltip>
                        }
                        nullComponent={
                            <Tooltip
                                title="This payment has been till reconciled. You are not authorized to edit this."
                                placement="top"
                            >
                                <Lock />
                            </Tooltip>
                        }
                    />
                ) : moment(payment.timestamp).isAfter(
                      moment('04/01/2024', 'DD/MM/YYYY')
                  ) &&
                  !isPaymentTillReconciled &&
                  !disabled ? (
                    <RoleCheck
                        permission={'delete_paymentLinePaymentForm'}
                        component={
                            isRemovable(payment) ? (
                                <IconButton
                                    onClick={() => handleRemove(payment.id)}
                                >
                                    <Clear />
                                </IconButton>
                            ) : null
                        }
                    />
                ) : disabled || payment.removed ? null : (
                    <RoleCheck
                        permission={'delete_paymentLinePaymentForm'}
                        component={
                            isRemovable(payment) ? (
                                <IconButton
                                    onClick={() => handleRemove(payment.id)}
                                >
                                    <Clear />
                                </IconButton>
                            ) : null
                        }
                    />
                )}
            </DataCell>

            <Dialog
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Reconciliation Details</DialogTitle>
                <DialogContent>
                    <Typography>
                        <b>ID:</b> {reconciliationDetails?.id}
                    </Typography>
                    <Typography>
                        <b>Reconciled By:</b>{' '}
                        {reconciliationDetails?.User?.firstName}{' '}
                        {reconciliationDetails?.User?.lastName}
                    </Typography>
                    <Typography>
                        <b>Date:</b>{' '}
                        {DateFormatter(reconciliationDetails?.createdAt)}{' '}
                        {moment(reconciliationDetails?.createdAt).format(
                            'h:mm A'
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    {/* // only enable unreconcile for direct deposit transactions for now */}
                    {payment.type === 'directDeposit' && (
                        <RoleCheck
                            permission="unreconcileTransaction, assign_superuser"
                            component={
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        setDialogOpen(true);
                                        setDrawerOpen(false);
                                    }}
                                >
                                    Unreconcile Transaction
                                </Button>
                            }
                        />
                    )}

                    <Button
                        variant="contained"
                        onClick={() =>
                            window.open(
                                `/admin/bankReconciliation/${moment(reconciliationDetails?.createdAt).format('YYYY-MM-DD')}/${reconciliationDetails?.id}`,
                                '_blank'
                            )
                        }
                    >
                        View Reconcilation
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>Unreconcile Transaction?</DialogTitle>
                <DialogContent>
                    {payment.type === 'directDeposit'
                        ? 'Unreconciling this transaction will also unreconcile the matching bank transaction. Are you sure you want to proceed?'
                        : 'Unreconciling this transaction will unreconcile both the Till that includes this payment and the matching bank transaction. Are you sure you want to proceed?'}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setDialogOpen(false);
                            setDrawerOpen(true);
                        }}
                    >
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() =>
                            UnreconcileTransaction(
                                payment,
                                saleDetails,
                                vehicleSaleDetails,
                                serviceDetails,
                                showSnackbar
                            )
                        }
                    >
                        Unreconcile Transaction
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default RemoveLine;

const CalculateVehicleStockBalanceSheet = (
    vehicleInventoryData: {
        endDate: string;
        data:
            | [
                  number,
                  { id: number; cost: number }[],
                  number,
                  { id: number; cost: number }[]
              ][]
            | [
                  number,
                  { id: number; cost: number }[],
                  number,
                  { id: number; cost: number }[],
                  number[]
              ][];
    }[]
) => {
    let newVehicleData = [];
    let usedVehicleData = [];
    let soldDuringSecondPeriod = [];
    for (const period of vehicleInventoryData) {
        let newVehicleDataPerSite = [];
        let usedVehicleDataPerSite = [];
        for (let array of period.data) {
            newVehicleDataPerSite.push(array[1]);
            usedVehicleDataPerSite.push(array[3]);

            if (array.length === 5) {
                soldDuringSecondPeriod.push(array[4]);
            }
        }
        newVehicleData.push(newVehicleDataPerSite);
        usedVehicleData.push(usedVehicleDataPerSite);
    }

    let newVehicletotalPerYear = newVehicleData.map((x) =>
        x.map((y) => y.reduce((acc, value) => acc + value.cost, 0))
    );
    let usedVehicletotalPerYear = usedVehicleData.map((x) =>
        x.map((y) => y.reduce((acc, value) => acc + value.cost, 0))
    );

    let newVehicleFiltered = [];
    for (const [index, year] of newVehicleData.entries()) {
        let currentYear = [];
        if (index + 1 < newVehicleData.length) {
            for (let site of year) {
                let soldVehicle = site.filter((x) =>
                    soldDuringSecondPeriod[index + 1].includes(x.id)
                );
                currentYear.push(soldVehicle);
            }
            newVehicleFiltered.push(currentYear);
        } else {
            newVehicleFiltered.push(null);
        }
    }

    let usedVehicleFiltered = [];
    for (const [index, year] of usedVehicleData.entries()) {
        let currentYear = [];
        if (index + 1 < usedVehicleData.length) {
            for (let site of year) {
                let soldVehicle = site.filter((x) =>
                    soldDuringSecondPeriod[index + 1].includes(x.id)
                );
                currentYear.push(soldVehicle);
            }
            usedVehicleFiltered.push(currentYear);
        } else {
            usedVehicleFiltered.push(null);
        }
    }

    // Get the list of vehicle sold each year that entered the stock the years prior, and calculate how much they were worth
    // They left the stock so they need to be removed from the balance sheet total
    let newVehicleCostSoldPerYear = newVehicleFiltered.map((x) =>
        x !== null
            ? x.map((y) => y.reduce((acc, value) => acc + value.cost, 0))
            : null
    );
    let usedVehicleCostSoldPerYear = usedVehicleFiltered.map((x) =>
        x !== null
            ? x.map((y) => y.reduce((acc, value) => acc + value.cost, 0))
            : null
    );

    // totalPerYear: contains one array for each period, and the array contains X values where X is the number of site and are the stock at the end of the period
    // costSoldPerYear: contains one array for each period, and the array contains X values where X is the number of site and the cost of vehicle that entered stock this period but got sold next period
    // To calculate the current total for the selected date we simply subtract from each element in totalPerYear the corresponding element in costSoldPerYear
    let newVehiclefinalData = [];
    for (let year = 0; year < newVehicletotalPerYear.length; year++) {
        let newYearData = [];
        for (let site = 0; site < newVehicletotalPerYear[0].length; site++) {
            if (newVehicleCostSoldPerYear[year] !== null) {
                newYearData.push(
                    newVehicletotalPerYear[year][site] -
                        newVehicleCostSoldPerYear[year][site]
                );
            } else {
                newYearData.push(newVehicletotalPerYear[year][site]);
            }
        }
        newVehiclefinalData.push(newYearData);
    }
    let usedVehiclefinalData = [];
    for (let year = 0; year < usedVehicletotalPerYear.length; year++) {
        let newYearData = [];
        for (let site = 0; site < usedVehicletotalPerYear[0].length; site++) {
            if (usedVehicleCostSoldPerYear[year] !== null) {
                newYearData.push(
                    usedVehicletotalPerYear[year][site] -
                        usedVehicleCostSoldPerYear[year][site]
                );
            } else {
                newYearData.push(usedVehicletotalPerYear[year][site]);
            }
        }
        usedVehiclefinalData.push(newYearData);
    }
    let newVehicleFinalDataFlattened = [];
    let usedVehicleFinalDataFlattened = [];
    for (let period of newVehiclefinalData) {
        for (let [index, site] of period.entries()) {
            if (newVehicleFinalDataFlattened.length === index) {
                newVehicleFinalDataFlattened.push(site);
            } else {
                newVehicleFinalDataFlattened[index] += site;
            }
        }
    }
    for (let period of usedVehiclefinalData) {
        for (let [index, site] of period.entries()) {
            if (usedVehicleFinalDataFlattened.length === index) {
                usedVehicleFinalDataFlattened.push(site);
            } else {
                usedVehicleFinalDataFlattened[index] += site;
            }
        }
    }

    return [newVehicleFinalDataFlattened, usedVehicleFinalDataFlattened];
};

export default CalculateVehicleStockBalanceSheet;

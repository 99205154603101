// IMPORTS
import {
    useState,
    useEffect,
    Dispatch,
    SetStateAction,
    ReactNode
} from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Tooltip,
    IconButton,
    Grid,
    TableRow,
    debounce
} from '@mui/material';
import { ArrowBack, Download, Upload } from '@mui/icons-material';
// COMPONENTS
import DataTable from '../../../global/tableComponents/DataTable';
import DataCellColoured from '../../../global/tableComponents/DataCellColoured';
import DataCell from '../../../global/tableComponents/DataCell';
import SpeedDial from '../../../global/SpeedDial';
import Paper from '../../../global/Paper';
// LOGIC
import CalculateSupplierTotalCost from '../logic/CalculateSupplierTotalCost';
import SubmitStockOrder from '../logic/SubmitStockOrder';
import GenerateOrderFilterRouter from '../logic/GenerateOrderFilterRouter';
import OrderWithAPIRouter from '../logic/OrderWithAPIRouter';
import { CurrencyFormatter } from '../../../global/logic/Formatters';
// INTERFACE
import { Api, Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Table } from '../interfaces/StockOrderInterface';
import api from '../../../../../api';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

interface OrderSummaryProps {
    tables: Table[];
    setTables: Dispatch<SetStateAction<Table[]>>;
    selectedSite: number;
    generatorList: Api[];
    setGeneratorList: Dispatch<SetStateAction<Api[]>>;
    setStaging: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>;
}

interface Email {
    id: number;
    reference: string;
    value: string;
}

const OrderSummary = ({
    tables,
    setTables,
    selectedSite,
    generatorList,
    setGeneratorList,
    setStaging,
    showSnackbar,
    setShouldBlockNavigation
}: OrderSummaryProps) => {
    const [actions, setActions] = useState<
        { icon: ReactNode; name: string; onClick: () => any }[]
    >([]);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [dialogSupplier, setDialogSupplier] = useState<Creditor>({});
    const [multipleEmailSupplier, setMultipleEmailSupplier] = useState<Email[]>(
        []
    );
    const [buttonLoading, setButtonLoading] = useState<boolean>(false);

    useEffect(() => {
        api.get('apiWithSupplier').then((res) => {
            setGeneratorList(res.data);
            let temp = {};
            let tempActions = [];
            tables.forEach((table) => {
                res.data.forEach((generator) => {
                    if (
                        table.supplierId === generator.SupplierId &&
                        checkAnySelected(table) &&
                        generator.canGenerateOrderFile &&
                        parseInt(localStorage.getItem('SiteId')) ===
                            generator.SiteId
                    ) {
                        let key = table.OrderType + table.supplierId;
                        temp[key] = {
                            generator: generator,
                            table: table
                        };
                        tempActions.push({
                            icon: <Download />,
                            name: `Generate Order File for ${table.supplierName} ${table.OrderType} order.`,
                            onClick: () =>
                                GenerateOrderFilterRouter(
                                    temp[key],
                                    tables,
                                    setTables,
                                    showSnackbar
                                )
                        });
                    }
                    if (
                        table.supplierId === generator.SupplierId &&
                        checkAnySelected(table) &&
                        generator.canOrderWithAPI &&
                        parseInt(localStorage.getItem('SiteId')) ===
                            generator.SiteId
                    ) {
                        let key = table.OrderType + table.supplierId;
                        temp[key] = {
                            generator: generator,
                            table: table
                        };
                        tempActions.push({
                            icon: <Upload />,
                            name: `Order for ${table.supplierName} ${table.OrderType} using the API.`,
                            onClick: () =>
                                OrderWithAPIRouter(
                                    temp[key],
                                    tables,
                                    setTables,
                                    showSnackbar
                                )
                        });
                    }
                });
            });
            setActions(tempActions);
        });
        // eslint-disable-next-line
    }, [tables]);

    var columns = [
        { id: 0, label: 'Part Number' },
        { id: 1, label: 'Name' },
        { id: 2, label: 'Qty On Hand' },
        { id: 3, label: 'Min Stock' },
        { id: 4, label: 'Max Stock' },
        { id: 5, label: 'Cost Price' },
        { id: 6, label: 'Row Total' },
        { id: 7, label: 'Quantity Ordering' }
    ];

    const dialogColumns = [
        { id: 0, label: 'Reference' },
        { id: 1, label: 'Email' },
        { id: 2, label: '' }
    ];

    const checkAnySelected = (table) => {
        var anySelected = false;

        table.orderLines.forEach((row) => {
            if (row.selected) {
                anySelected = true;
            }
        });

        return anySelected;
    };

    const handleSelectEmail = (email, supplierId) => {
        for (let table of tables) {
            if (table.Supplier.id === supplierId) {
                table.selectedEmail = email;
            }
        }
        setDialogOpen(false);
        SubmitStockOrder(
            tables,
            selectedSite,
            showSnackbar,
            'email',
            setShouldBlockNavigation,
            setButtonLoading,
            setDialogOpen,
            setDialogSupplier,
            setMultipleEmailSupplier
        );
    };

    return (
        <Paper textAlign="center">
            <Typography variant="h4" align="center">
                <IconButton onClick={() => setStaging(false)}>
                    <ArrowBack />
                </IconButton>
                Order Summary
            </Typography>
            <Typography variant="body1" align="center">
                Please review the order below before submitting.
            </Typography>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}></Grid>
                <Grid item xs={2} textAlign="right">
                    <Tooltip
                        placement="left"
                        title="This will submit the order to Ora as well as email the selected creditor."
                    >
                        <LoadingButton
                            variant="contained"
                            loading={buttonLoading}
                            fullWidth
                            onClick={debounce(
                                () =>
                                    SubmitStockOrder(
                                        tables,
                                        selectedSite,
                                        showSnackbar,
                                        'email',
                                        setShouldBlockNavigation,
                                        setButtonLoading,
                                        setDialogOpen,
                                        setDialogSupplier,
                                        setMultipleEmailSupplier
                                    ),
                                300
                            )}
                        >
                            Email Submission
                        </LoadingButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={2} textAlign="left">
                    <Tooltip
                        placement="right"
                        title="This will submit the order to Ora only. No creditors will be emailed."
                    >
                        <LoadingButton
                            variant="contained"
                            fullWidth
                            loading={buttonLoading}
                            onClick={debounce(
                                () =>
                                    SubmitStockOrder(
                                        tables,
                                        selectedSite,
                                        showSnackbar,
                                        '',
                                        setShouldBlockNavigation,
                                        setButtonLoading
                                    ),
                                300
                            )}
                        >
                            Ora Submission
                        </LoadingButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>
            <br />
            {tables?.map((table) =>
                checkAnySelected(table) ? (
                    <>
                        <Typography variant="h6">
                            <b>{table.supplierName}</b>
                            <br />
                            {CurrencyFormatter(
                                CalculateSupplierTotalCost(
                                    tables,
                                    table.supplierId,
                                    table.OrderType
                                )
                            )}{' '}
                            - {table.OrderType.toUpperCase()} ORDER
                        </Typography>
                        <DataTable columns={columns}>
                            {table?.orderLines?.map((row) =>
                                row.selected ? (
                                    <TableRow key={row.id}>
                                        <DataCell>{row.partNumber}</DataCell>
                                        <DataCell>{row.name}</DataCell>
                                        <DataCell>{row.qtyOnHand}</DataCell>
                                        <DataCell>{row.minStock}</DataCell>
                                        <DataCell>{row.maxStock}</DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(row.costPrice)}
                                        </DataCell>
                                        <DataCell>
                                            {CurrencyFormatter(
                                                row.costPrice * row.quantity
                                            )}
                                        </DataCell>
                                        <DataCell>{row.quantity}</DataCell>
                                    </TableRow>
                                ) : null
                            )}
                        </DataTable>
                        <br />
                        <br />
                    </>
                ) : null
            )}

            {actions.length > 0 ? <SpeedDial actionsList={actions} /> : null}

            <Dialog
                open={dialogOpen}
                onClose={() => {
                    setDialogOpen(false);
                }}
            >
                <DialogTitle>
                    Multiple Email Addresses Found for {dialogSupplier.name}
                </DialogTitle>
                <DialogContent>
                    <DataTable columns={dialogColumns}>
                        {multipleEmailSupplier.map((email) => (
                            <TableRow>
                                <DataCell>{email.reference}</DataCell>
                                <DataCell>{email.value}</DataCell>
                                <DataCellColoured
                                    handleClick={() =>
                                        handleSelectEmail(
                                            email.value,
                                            dialogSupplier.id
                                        )
                                    }
                                >
                                    SELECT
                                </DataCellColoured>
                            </TableRow>
                        ))}
                    </DataTable>
                </DialogContent>
            </Dialog>
        </Paper>
    );
};

export default OrderSummary;

import { set } from 'date-fns';
import api from '../../../../../api';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { ServiceCode } from '../../../global/interfaces/ServiceInterface';

interface HandleDeleteServiceCodePros {
    id: number;
    setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
    setButtonLoading: React.Dispatch<React.SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const HandleDeleteServiceCode = ({
    id,
    setOpenDeleteDialog,
    setButtonLoading,
    showSnackbar
}: HandleDeleteServiceCodePros) => {
    api.delete(`serviceCodeNextGen/${id}`).then((res) => {
        if (res.status === 200) {
            setButtonLoading(false);
            setOpenDeleteDialog(false);
            showSnackbar('Service Code Successfully Deleted.');
            window.location.reload();
        } else {
            showSnackbar(
                'Whoops! Something went wrong on our end.',
                'Please contact your IT department.',
                'error'
            );
        }
    });
};

export default HandleDeleteServiceCode;

import api from '../../../../../api';
import { Order } from '../../interfaces/PartsInterface';
import { Service } from '../../interfaces/ServiceInterface';
import { VehicleSale } from '../../interfaces/VehicleSaleInterface';

const GetReconciliationDetails = (
    saleDetails: Order,
    vehicleSaleDetails: VehicleSale,
    serviceDetails: Service,
    BankReconciliationId: number,
    setReconciliationDetails
) => {
    let query = '';
    // since we are using BankReconciliationId, we don't need to check for saleDetails?.id, vehicleSaleDetails?.id, serviceDetails?.id
    // keep them for now in case we need to revert back
    if (BankReconciliationId) {
        query = 'BankReconciliationId=' + BankReconciliationId;
    } else if (saleDetails?.id) {
        query = 'OrderId=' + saleDetails?.id;
    } else if (vehicleSaleDetails?.id) {
        query = 'VehicleSaleId=' + vehicleSaleDetails?.id;
    } else if (serviceDetails?.id) {
        query = 'ServiceId=' + serviceDetails?.id;
    }

    api.get(`getSepcificReconciliationDetails?${query}`).then((res) => {
        setReconciliationDetails(res.data);
    });
};

export default GetReconciliationDetails;

// IMPORTS
import { useState } from 'react';
import {
    Typography,
    TableRow,
    TableCell,
    Tooltip,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button
} from '@mui/material';
import { Add, Delete, FileDownload } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
// COMPONENTS
import PageWrapper from '../../global/PageWrapper';
import Paper from '../../global/Paper';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import TableSearch from '../../global/tableComponents/TableSearch';
// LOGIC
import HandleDownloadResults from '../../global/logic/HandleDownloadResults';
// INTERFACES
import { Filter } from '../../global/interfaces/FilterInterface';
import { ServiceCode } from '../../global/interfaces/ServiceInterface';
import { LoadingButton } from '@mui/lab';
import HandleDeleteServiceCode from './logic/HandleDeleteServiceCode';
import { withSnackbar } from '../../global/WrappingSnackbar';
import { showSnackbar } from '../../global/interfaces/GlobalInterface';

// Default values for the filter and sort for initial set state and clear filter
const clearedFilter = {};
const clearedSort = ['id', 'DESC'];

const ServiceCodeTable = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const SiteId = parseInt(localStorage.getItem('SiteId'));
    const history = useHistory();
    const [filter, setFilter] = useState<Filter>(
        JSON.parse(JSON.stringify(clearedFilter))
    );
    const [sort, setSort] = useState(clearedSort);
    const [resultsList, setResultsList] = useState<ServiceCode[]>([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [selectedServiceCode, setSelectedServiceCode] =
        useState<ServiceCode>();
    const [buttonLoading, setButtonLoading] = useState(false);

    // Options for the speed dial
    const actionsList = [
        {
            icon: <Add />,
            name: 'Add Code',
            onClick: () => history.push('/service/codes/add')
        },
        {
            icon: <FileDownload />,
            name: 'Export Current Results',
            onClick: () => handleDownloadResults()
        }
    ];

    // Columns for the data table
    const columns = [
        { id: 0, label: 'ID', name: 'id', sort: true, width: 100 },
        { id: 1, label: 'Name', width: 200 },
        { id: 2, label: 'Standard Labour Charge', width: 100 },
        { id: 3, label: 'Standard Duration (hour)', width: 100 },
        { id: 4, label: 'Job Description', width: 300 },
        { id: 5, label: 'Invoice Description', width: 300 },
        { id: 6, label: '', width: 100 }
    ];

    // Handle downloading the csv
    const handleDownloadResults = () => {
        HandleDownloadResults(
            sort,
            'serviceCodeSearchNextGen',
            handleRequestCreate(),
            'OraServiceCodeResults.csv',
            [
                'ID',
                'Code',
                'Standard Labour Charge',
                'Job Card Description',
                'Invoice Description'
            ],
            [
                'id',
                'serviceCode',
                'servicePrice',
                'jobCardDescription',
                'invoiceDescription'
            ]
        );
    };

    // Combines the selected filters and generates a string to put in the api request query
    const handleRequestCreate = () => {
        return `&site=${SiteId}`;
    };

    // Checks if any options for filter have been selected and return result
    const isFilterActive = () => {
        return false;
    };

    const onDeleteServiceCode = (serviceCodeId) => {
        setButtonLoading(true);
        HandleDeleteServiceCode({
            id: serviceCodeId,
            setOpenDeleteDialog,
            setButtonLoading,
            showSnackbar
        });
    };

    return (
        <PageWrapper>
            <Typography variant="h4">Service Codes</Typography>
            <br />
            <TableSearch
                searchTitle="Search Service Code"
                showFilter={false}
                showPagination={true}
                showPaper={true}
                filter={filter}
                setFilter={setFilter}
                clearedFilter={clearedFilter}
                sort={sort}
                handleRequestCreate={handleRequestCreate}
                route="serviceCodeSearchNextGen"
                setResultsList={setResultsList}
                isFilterActive={isFilterActive}
            ></TableSearch>

            <Paper>
                <DataTable
                    columns={columns}
                    sort={sort}
                    setSort={setSort}
                    showSpeedDial={true}
                    actionsList={actionsList}
                >
                    {resultsList.map((row) => (
                        <TableRow key={row.id}>
                            <DataCellColoured
                                handleClick={() =>
                                    history.push(
                                        `/service/codes/view/${row.id}`
                                    )
                                }
                            >
                                {row.id}
                            </DataCellColoured>
                            <DataCell>{row.serviceCode}</DataCell>
                            <DataCell>{row.servicePrice}</DataCell>
                            <DataCell>
                                {row.serviceDuration
                                    ? `${row.serviceDuration > 0 ? Math.floor(row.serviceDuration).toString() + ' hour(s)' : ''} ${row.serviceDuration > 0 ? ((row.serviceDuration * 60) % 60) + ' minute(s)' : ''}`
                                    : ''}
                            </DataCell>
                            {/* Using standard table cell so that the descriptions are left aligned */}
                            <TableCell>{row.jobCardDescription}</TableCell>
                            <TableCell>{row.invoiceDescription}</TableCell>
                            <DataCell align="left">
                                <Tooltip
                                    title="Delete Service Code"
                                    placement="top"
                                >
                                    <IconButton
                                        onClick={() => {
                                            setOpenDeleteDialog(true);
                                            setSelectedServiceCode(row);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </DataCell>
                        </TableRow>
                    ))}
                </DataTable>
            </Paper>
            <Dialog
                maxWidth="sm"
                fullWidth
                open={openDeleteDialog}
                onClose={() => {
                    setOpenDeleteDialog(false);
                }}
            >
                <DialogTitle>Delete Service Code</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        Are you sure you would like to delete the service code{' '}
                        {selectedServiceCode?.serviceCode}?
                        <br />
                        <b>This cannot be undone.</b>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setOpenDeleteDialog(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        loading={buttonLoading}
                        onClick={() =>
                            onDeleteServiceCode(selectedServiceCode?.id)
                        }
                    >
                        Delete
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </PageWrapper>
    );
};

export default withSnackbar(ServiceCodeTable);
// IMPORTS
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    TextField,
    Paper,
    MenuItem,
    Grid,
    Tooltip,
    IconButton,
    CircularProgress,
    Dialog,
    DialogTitle,
    LinearProgress,
    DialogContent,
    Table,
    TableCell,
    TableRow,
    useTheme
} from '@mui/material';
import { Refresh, FileDownload, Check } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';

// COMPONENTS
import BalanceSheetComponents from './components/BalanceSheetComponents';

// LOGIC
import CalculateBalanceSheet from './logic/CalculateBalanceSheet';
import CombineBalanceSheetLines from './logic/CombineBalanceSheetLines';
import GetAllSites from '../../../../global/databaseLogic/GetAllSites';
import ExportBalanceSheetToCSV from './logic/ExportBalanceSheetToCSV';

// INTERFACE
import { Site } from '../../../../global/interfaces/GeneralInterface';
import { BalanceSheetLine } from './interface/BalanceSheetInterface';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { withSnackbar } from '../../../../global/WrappingSnackbar';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';

const BalanceSheet: (props: {
    loading: boolean;
    setLoading: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}) => JSX.Element = (props) => {
    const currentMode = useTheme().palette.mode;
    const [queryDate, setQueryDate] = useState<Dayjs>(dayjs());
    const [selectedSite, setSelectedSite] = useState<string | Site>(
        'All Sites'
    );
    const [showSiteSelector, setShowSiteSelector] = useState<boolean>(false);
    const [sites, setSites] = useState<Site[]>([]);
    const [showButtons, setShowButtons] = useState<boolean>(false);

    const [allLines, setAllLines] = useState<BalanceSheetLine[]>([]);
    const [singleLine, setSingleLine] = useState<BalanceSheetLine>();
    const [progress, setProgress] = useState<number>(0);
    const [progressDetails, setProgressDetails] = useState<string[]>([]);

    useEffect(() => {
        GetAllSites(setSites);
    }, []);

    const handleChangeSite = (value: string | number) => {
        if (value === 'All Sites') {
            setSelectedSite(value);
        } else {
            for (let site of sites) {
                if (site.id === value) {
                    setSelectedSite(site);
                }
            }
        }
        if (value === 'All Sites') {
            CombineBalanceSheetLines(allLines, setSingleLine);
        } else {
            let tempSingleLine = allLines.filter((x) => x.SiteId === value);
            setSingleLine(tempSingleLine[0]);
        }
    };

    return (
        <>
            <Paper className="paper-padding">
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label="Balance Sheet Date"
                                format="DD/MM/YYYY"
                                value={queryDate}
                                onChange={(newValue) =>
                                    setQueryDate(newValue.endOf('day'))
                                }
                                slotProps={{
                                    textField: {
                                        fullWidth: true,
                                        error: false,
                                        variant: 'outlined',
                                        InputLabelProps: { shrink: true }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2}>
                        {showSiteSelector ? (
                            <TextField
                                select
                                fullWidth
                                label="Site"
                                value={
                                    typeof selectedSite === 'string'
                                        ? selectedSite
                                        : selectedSite.id
                                }
                                onChange={(e) =>
                                    handleChangeSite(e.target.value)
                                }
                                InputLabelProps={{ shrink: true }}
                            >
                                {sites.map((site) => (
                                    <MenuItem value={site.id}>
                                        {site.name}
                                    </MenuItem>
                                ))}
                                <MenuItem value="All Sites">All Sites</MenuItem>
                            </TextField>
                        ) : null}
                    </Grid>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={3} textAlign="right">
                        {showButtons ? (
                            <>
                                <Tooltip
                                    title="Export to CSV"
                                    placement="bottom"
                                >
                                    <IconButton
                                        onClick={() =>
                                            ExportBalanceSheetToCSV({
                                                queryDate: queryDate,
                                                selectedSite: selectedSite,
                                                singleLine: singleLine
                                            })
                                        }
                                    >
                                        <FileDownload />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    title="Refresh Page"
                                    placement="bottom"
                                >
                                    <IconButton
                                        onClick={() => window.location.reload()}
                                    >
                                        <Refresh />
                                    </IconButton>
                                </Tooltip>
                            </>
                        ) : null}
                        <LoadingButton
                            loading={props.loading}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                CalculateBalanceSheet(
                                    queryDate,
                                    setAllLines,
                                    setShowSiteSelector,
                                    setSingleLine,
                                    props.setLoading,
                                    setProgress,
                                    setProgressDetails,
                                    props.showSnackbar
                                );
                                setShowButtons(true);
                            }}
                        >
                            Generate Report
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Paper>
            <br />
            <BalanceSheetComponents
                singleLine={singleLine}
                selectedSite={selectedSite}
                queryDate={queryDate}
            />
            <Dialog open={props.loading}>
                <DialogTitle>Retrieving Data for Balance Sheet</DialogTitle>
                <LinearProgress variant="determinate" value={progress} />
                <DialogContent>
                    <Table>
                        <TableRow>
                            <TableCell>Accounts</TableCell>
                            <TableCell>
                                {progressDetails.includes('Accounts') ? (
                                    <Check style={{ color: 'green' }} />
                                ) : (
                                    <CircularProgress
                                        sx={{
                                            color:
                                                currentMode === 'dark'
                                                    ? '#8d65dd'
                                                    : '#9370DB'
                                        }}
                                        size="15px"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Customer Deposit</TableCell>
                            <TableCell>
                                {progressDetails.includes('Deposit') ? (
                                    <Check style={{ color: 'green' }} />
                                ) : (
                                    <CircularProgress
                                        sx={{
                                            color:
                                                currentMode === 'dark'
                                                    ? '#8d65dd'
                                                    : '#9370DB'
                                        }}
                                        size="15px"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Floorplan Liability</TableCell>
                            <TableCell>
                                {progressDetails.includes('Floorplan') ? (
                                    <Check style={{ color: 'green' }} />
                                ) : (
                                    <CircularProgress
                                        sx={{
                                            color:
                                                currentMode === 'dark'
                                                    ? '#8d65dd'
                                                    : '#9370DB'
                                        }}
                                        size="15px"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Vehicle Inventory</TableCell>
                            <TableCell>
                                {progressDetails.includes('Vehicle') ? (
                                    <Check style={{ color: 'green' }} />
                                ) : (
                                    <CircularProgress
                                        sx={{
                                            color:
                                                currentMode === 'dark'
                                                    ? '#8d65dd'
                                                    : '#9370DB'
                                        }}
                                        size="15px"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Stock Other</TableCell>
                            <TableCell>
                                {progressDetails.includes('Stock') ? (
                                    <Check style={{ color: 'green' }} />
                                ) : (
                                    <CircularProgress
                                        sx={{
                                            color:
                                                currentMode === 'dark'
                                                    ? '#8d65dd'
                                                    : '#9370DB'
                                        }}
                                        size="15px"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Debtor Payment</TableCell>
                            <TableCell>
                                {progressDetails.includes('Debtor') ? (
                                    <Check style={{ color: 'green' }} />
                                ) : (
                                    <CircularProgress
                                        sx={{
                                            color:
                                                currentMode === 'dark'
                                                    ? '#8d65dd'
                                                    : '#9370DB'
                                        }}
                                        size="15px"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Pending Payment Runs</TableCell>
                            <TableCell>
                                {progressDetails.includes('Payrun') ? (
                                    <Check style={{ color: 'green' }} />
                                ) : (
                                    <CircularProgress
                                        sx={{
                                            color:
                                                currentMode === 'dark'
                                                    ? '#8d65dd'
                                                    : '#9370DB'
                                        }}
                                        size="15px"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Gift Voucher</TableCell>
                            <TableCell>
                                {progressDetails.includes('GiftVoucher') ? (
                                    <Check style={{ color: 'green' }} />
                                ) : (
                                    <CircularProgress
                                        sx={{
                                            color:
                                                currentMode === 'dark'
                                                    ? '#8d65dd'
                                                    : '#9370DB'
                                        }}
                                        size="15px"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    </Table>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default withSnackbar(BalanceSheet);

// IMPORTS
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'; // !DO NOT REMOVE
// LOGIC
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
import {
    CurrencyFormatter,
    DateFormatter,
    ToFixed
} from '../../../global/logic/Formatters';
import dayjs from 'dayjs';
// eslint-disable-next-line
import isBetween from 'dayjs/plugin/isBetween'; // DO NOT REMOVE

// Get total amount for multiple payment made to a single Order
const getTotalAmount = (rows) => {
    let total = 0;
    for (let row of rows) {
        total += row.amountDue;
    }
    return total;
};

// Get the amount paid for a specific debtor payment
const getAmountPaid = (row) => {
    let total = 0;
    for (let line of row.paymentHistory) {
        total += line.amount;
    }
    return total;
};

// Get the amount paid for multiple debtor payment
const getAmountPaidMultiplePayment = (rows) => {
    let total = 0;
    for (let row of rows) {
        for (let line of row.paymentHistory) {
            total += line.amount;
        }
    }
    return total;
};

const getAmountRefunded = (refundArray) => {
    let total = 0;
    for (let row of refundArray) {
        total += Math.abs(row.amountDue);
    }
    return total;
};

const PDFDebtorInvoice = async (
    selectedDebtor,
    balance: number,
    startDate: string,
    endDate: string,
    displayProformaOnStatement: boolean
) => {
    let showCustomerDetails = true;
    let showBankDetails = true;

    var doc = new jsPDF({
        orientation: 'p',
        format: 'a4'
    });

    // Header for the invoice
    await InvoiceHeader(
        doc,
        parseInt(localStorage.getItem('SiteId')),
        'Account Statement',
        `Customer #${selectedDebtor.CustomerId}`,
        showCustomerDetails,
        showBankDetails,
        true,
        selectedDebtor.CustomerDetails,
        null,
        String(new Date(Date.now()))
    );

    // Table continaing the payment lines
    let header = [
        ['Invoice Number', 'Amount', 'Paid', 'Owing', 'Type', 'Date']
    ];
    let paymentHeader = [['Payment ID', 'Type', 'Amount', 'Date']];
    let body = [];
    let paymentBody = [];
    let current = 0;
    let thirtyDays = 0;
    let sixtyDays = 0;
    let ninetyDays = 0;

    let selectedRows = [...selectedDebtor.rows];
    for (let row of selectedRows) {
        row.rowDate = row?.Order?.finalisedAt
            ? row?.Order?.finalisedAt
            : row?.Service?.finalisedDate
              ? row.Service.finalisedDate
              : row.createdAt;
    }

    selectedRows = selectedRows.sort(
        (a, b) => dayjs(b.rowDate).unix() - dayjs(a.rowDate).unix()
    );

    let ordersAlreadyProcessed = [];
    for (let row of selectedRows) {
        let rowDate = row.rowDate;

        if (row.amountDue > 0) {
            let refundArray = selectedDebtor.rows.filter(
                (x) =>
                    ((x.OrderId != null && x.OrderId === row.OrderId) ||
                        (x.ServiceId != null &&
                            x.ServiceId === row.ServiceId)) &&
                    x.amountDue < 0
            );
            let amountRefunded =
                refundArray.length > 0 ? getAmountRefunded(refundArray) : 0;
            let line = null;

            // CHECK IF ORDER ID AND ORDER ID NOT USED ALREADY
            if (
                row.OrderId != null &&
                !ordersAlreadyProcessed.includes(row.OrderId)
            ) {
                let orderRows = selectedRows.filter(
                    (x) => x.OrderId === row.OrderId
                );

                line = [
                    row.OrderId,
                    CurrencyFormatter(getTotalAmount(orderRows)),
                    CurrencyFormatter(getAmountPaidMultiplePayment(orderRows)),
                    CurrencyFormatter(
                        getTotalAmount(orderRows) -
                            getAmountPaidMultiplePayment(orderRows)
                    ),
                    getTotalAmount(orderRows) < 0
                        ? ' P&A Credit'
                        : `P&A ${row?.Order?.status}`,
                    DateFormatter(rowDate)
                ];

                if (row?.Order?.status === 'Sale') {
                    if (dayjs(rowDate).isSame(dayjs(startDate), 'month')) {
                        current +=
                            getTotalAmount(orderRows) -
                            getAmountPaidMultiplePayment(orderRows);
                    } else if (
                        dayjs(rowDate).isSame(
                            dayjs(startDate).subtract(1, 'month'),
                            'month'
                        )
                    ) {
                        thirtyDays +=
                            getTotalAmount(orderRows) -
                            getAmountPaidMultiplePayment(orderRows);
                    } else if (
                        dayjs(rowDate).isSame(
                            dayjs(startDate).subtract(2, 'month'),
                            'month'
                        )
                    ) {
                        sixtyDays +=
                            getTotalAmount(orderRows) -
                            getAmountPaidMultiplePayment(orderRows);
                    } else if (
                        dayjs(rowDate).isBefore(
                            dayjs(startDate).subtract(2, 'month')
                        )
                    ) {
                        ninetyDays +=
                            getTotalAmount(orderRows) -
                            getAmountPaidMultiplePayment(orderRows);
                    }
                }

                let refundBody = [];
                for (let refundLine of refundArray) {
                    let line = [
                        row.OrderId,
                        'Refund',
                        CurrencyFormatter(refundLine.amountDue),
                        '',
                        'P&A Credit',
                        DateFormatter(refundLine.createdAt)
                    ];
                    refundBody.push(line);
                }

                if (
                    (displayProformaOnStatement &&
                        row?.Order?.status === 'Proforma') ||
                    (row?.Order?.status === 'Sale' &&
                        getTotalAmount(orderRows) -
                            getAmountPaidMultiplePayment(orderRows) !==
                            0)
                ) {
                    // Only display line with a date between the beginning and the end of the month
                    if (
                        dayjs(rowDate).isBetween(
                            dayjs(startDate),
                            dayjs(endDate).add(1, 'day'),
                            'day'
                        )
                    ) {
                        body.push(line);
                        if (refundBody.length > 0) {
                            for (let refundLine of refundBody) {
                                body.push(refundLine);
                            }
                        }
                    } else if (
                        dayjs(rowDate).isBefore(dayjs(startDate)) &&
                        ToFixed(
                            row.amountDue - getAmountPaid(row) + amountRefunded
                        ) !== 0
                    ) {
                        body.push(line);
                        if (refundBody.length > 0) {
                            for (let refundLine of refundBody) {
                                body.push(refundLine);
                            }
                        }
                    }
                }
                ordersAlreadyProcessed.push(row.OrderId);
            } else if (row.ServiceId != null) {
                line = [
                    row.ServiceId,
                    CurrencyFormatter(row.amountDue),
                    CurrencyFormatter(getAmountPaid(row)),
                    CurrencyFormatter(row.amountDue - getAmountPaid(row)),
                    row.amountDue < 0
                        ? `Service Credit #${row.ServiceId}`
                        : `Service #${row.ServiceId}`,
                    DateFormatter(rowDate)
                ];

                let refundBody = [];
                for (let refundLine of refundArray) {
                    let line = [
                        row.OrderId,
                        'Refund',
                        CurrencyFormatter(refundLine.amountDue),
                        '',
                        'Service Credit',
                        DateFormatter(refundLine.createdAt)
                    ];
                    refundBody.push(line);
                }

                if (row?.Service?.serviceComplete === 'Finalised') {
                    if (dayjs(rowDate).isSame(dayjs(startDate), 'month')) {
                        current += row.amountDue - getAmountPaid(row);
                    } else if (
                        dayjs(rowDate).isSame(
                            dayjs(startDate).subtract(1, 'month'),
                            'month'
                        )
                    ) {
                        thirtyDays += row.amountDue - getAmountPaid(row);
                    } else if (
                        dayjs(rowDate).isSame(
                            dayjs(startDate).subtract(2, 'month'),
                            'month'
                        )
                    ) {
                        sixtyDays += row.amountDue - getAmountPaid(row);
                    } else if (
                        dayjs(rowDate).isBefore(
                            dayjs(startDate).subtract(2, 'month')
                        )
                    ) {
                        ninetyDays += row.amountDue - getAmountPaid(row);
                    }
                }

                if (row?.Service?.serviceComplete === 'Finalised') {
                    // Only display line with a date between the beginning and the end of the month
                    if (
                        dayjs(rowDate).isBetween(
                            dayjs(startDate),
                            dayjs(endDate).add(1, 'day'),
                            'day'
                        )
                    ) {
                        body.push(line);
                        if (refundBody.length > 0) {
                            for (let refundLine of refundBody) {
                                body.push(refundLine);
                            }
                        }
                    } else if (
                        dayjs(rowDate).isBefore(dayjs(startDate)) &&
                        ToFixed(
                            row.amountDue - getAmountPaid(row) + amountRefunded
                        ) !== 0
                    ) {
                        body.push(line);
                        if (refundBody.length > 0) {
                            for (let refundLine of refundBody) {
                                body.push(refundLine);
                            }
                        }
                    }
                }
            } else if (row.OrderId == null && row.ServiceId == null) {
                line = [
                    row.StockInvoiceId,
                    CurrencyFormatter(row.amountDue),
                    CurrencyFormatter(getAmountPaid(row)),
                    CurrencyFormatter(row.amountDue - getAmountPaid(row)),
                    row.StockInvoiceId
                        ? row.StockInvoice?.documentReference
                        : row.amountDue < 0
                          ? 'Account Payment'
                          : 'Misc',
                    DateFormatter(rowDate)
                ];

                if (
                    row?.Order?.status === 'Sale' ||
                    row?.Service?.serviceComplete === 'Finalised'
                ) {
                    if (dayjs(rowDate).isSame(dayjs(startDate), 'month')) {
                        current += row.amountDue - getAmountPaid(row);
                    } else if (
                        dayjs(rowDate).isSame(
                            dayjs(startDate).subtract(1, 'month'),
                            'month'
                        )
                    ) {
                        thirtyDays += row.amountDue - getAmountPaid(row);
                    } else if (
                        dayjs(rowDate).isSame(
                            dayjs(startDate).subtract(2, 'month'),
                            'month'
                        )
                    ) {
                        sixtyDays += row.amountDue - getAmountPaid(row);
                    } else if (
                        dayjs(rowDate).isBefore(
                            dayjs(startDate).subtract(2, 'month')
                        )
                    ) {
                        ninetyDays += row.amountDue - getAmountPaid(row);
                    }
                }

                // Only display line with a date between the beginning and the end of the month
                if (
                    dayjs(rowDate).isBetween(
                        dayjs(startDate),
                        dayjs(endDate).add(1, 'day'),
                        'day'
                    )
                ) {
                    body.push(line);
                } else if (
                    dayjs(rowDate).isBefore(dayjs(startDate)) &&
                    ToFixed(
                        row.amountDue - getAmountPaid(row) + amountRefunded
                    ) !== 0
                ) {
                    body.push(line);
                }
            }
        } else if (row.amountDue < 0 && row.OrderId == null) {
            rowDate = row?.Order?.finalisedAt
                ? row?.Order?.finalisedAt
                : row?.Service?.finalisedDate
                  ? row.Service.finalisedDate
                  : row.paymentDueDate;
            let paymentLine = [
                row.id,
                row.StockInvoiceId
                    ? row.StockInvoice?.documentReference
                    : row.OrderId
                      ? row.amountDue < 0
                          ? 'P&A Credit'
                          : `P&A ${row?.Order?.status}`
                      : row.ServiceId
                        ? row.amountDue < 0
                            ? `Service Credit #${row.ServiceId}`
                            : `Service #${row.ServiceId}`
                        : row.amountDue < 0
                          ? 'Account Payment'
                          : 'Misc',
                CurrencyFormatter(Math.abs(row.amountDue)),
                DateFormatter(
                    row?.Order?.finalisedAt
                        ? row?.Order?.finalisedAt
                        : dayjs(row.paymentDueDate).add(1, 'day').toISOString()
                )
            ];
            // Only display line with a date between the beginning and the end of the month
            if (
                dayjs(rowDate).isBetween(
                    dayjs(startDate),
                    dayjs(endDate).add(1, 'day'),
                    'day'
                )
            ) {
                paymentBody.push(paymentLine);
            }
        }
    }

    let lastYcoordinates = 82;

    doc.text(
        `From ${DateFormatter(dayjs(startDate).toISOString())} to ${DateFormatter(dayjs(endDate).toISOString())}`,
        10,
        lastYcoordinates + 10
    );

    doc.setFont('helvetica', 'bold')
        .setFontSize(12)
        .text('Invoice', 10, lastYcoordinates + 15);

    lastYcoordinates = await InvoiceTable(
        doc,
        lastYcoordinates + 18,
        header,
        body
    );

    if (paymentBody.length > 0) {
        doc.setFont('helvetica', 'bold')
            .setFontSize(12)
            .text('Payment', 10, lastYcoordinates + 12);
        lastYcoordinates = await InvoiceTable(
            doc,
            lastYcoordinates + 15,
            paymentHeader,
            paymentBody
        );
    }

    doc.setFont('helvetica', 'bold')
        .setFontSize(12)
        .text('Due', 10, lastYcoordinates + 12);
    autoTable(doc, {
        head: [['Current', '30 Days', '60 Days', '90+ Days', 'Balance']],
        body: [
            [
                CurrencyFormatter(current),
                CurrencyFormatter(thirtyDays),
                CurrencyFormatter(sixtyDays),
                CurrencyFormatter(ninetyDays),
                CurrencyFormatter(current + thirtyDays + sixtyDays + ninetyDays)
            ]
        ],
        startY: lastYcoordinates + 15,
        theme: 'grid',
        headStyles: {
            fillColor: [19, 65, 123],
            textColor: 'white',
            lineColor: [19, 65, 123]
        },
        margin: { bottom: 20 },
        styles: { lineColor: [201, 201, 201], lineWidth: 0.1 }
    });

    // Open the pdf
    window.open(doc.output('bloburl'));
};

export default PDFDebtorInvoice;

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MenuItem, TextField, Typography } from '@mui/material';
import VoucherDrawerContent from '../../../parts/newSale/components/VoucherDrawerContent';
import QuickSaleUpdate from '../../../parts/newSale/logic/updateFunctions/QuickSaleUpdate';
import Drawer from '../../Drawer';
import RoleCheck from '../../RoleCheck';
import DataCell from '../../tableComponents/DataCell';
import GetVoucher from '../../../parts/newSale/logic/GetVoucher';
import GetCompanyDetails from '../../databaseLogic/GetCompanyDetails';
import { Customer, PaymentLine } from '../../interfaces/GeneralInterface';
import { showSnackbar } from '../../interfaces/GlobalInterface';
import { Order, Voucher } from '../../interfaces/PartsInterface';
import { Service } from '../../interfaces/ServiceInterface';
import { VehicleSale } from '../../interfaces/VehicleSaleInterface';

interface PaymentMethodProps {
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    saleDetails: Order;
    vehicleSaleDetails: VehicleSale;
    serviceDetails: Service;
    payment: PaymentLine;
    showSnackbar: showSnackbar;
    payingCustomer: Customer;
    handleAddPayment?: (paymentAmount: string, paymentType?: string) => any;
    disabled: boolean;
    isPaymentTillReconciled: boolean;
    isFinalised: boolean;
}

const PaymentMethod = ({
    paymentLines,
    setPaymentLines,
    saleDetails,
    vehicleSaleDetails,
    serviceDetails,
    payment,
    showSnackbar,
    payingCustomer,
    handleAddPayment,
    disabled,
    isPaymentTillReconciled,
    isFinalised
}: PaymentMethodProps) => {
    const [settings, setSettings] = useState(null);
    const [voucher, setVoucher] = useState<Voucher>();
    const [voucherDrawerOpen, setVoucherDrawerOpen] = useState<boolean>(false);

    // Get the payment methods
    useEffect(() => {
        GetCompanyDetails(
            parseInt(localStorage.getItem('SiteId')),
            setSettings
        );
    }, []);

    // Update a payment type
    const updatePaymentType = (
        id: number,
        value: string,
        inactive?: boolean
    ) => {
        var index = paymentLines.findIndex((x) => x.id === id);
        let rows = [...paymentLines];
        let row = { ...rows[index] };

        row.type = value;
        rows[index] = row;
        setPaymentLines(rows);

        if (inactive) {
            QuickSaleUpdate(saleDetails.id, rows, showSnackbar, 'partSplitted');
        } else if (saleDetails?.id && saleDetails?.status === 'Sale') {
            if (row.amount < 0) {
                QuickSaleUpdate(
                    saleDetails.id,
                    rows,
                    showSnackbar,
                    'partCredit'
                );
            } else {
                QuickSaleUpdate(saleDetails.id, rows, showSnackbar, 'part');
            }
        } else if (
            vehicleSaleDetails?.id &&
            vehicleSaleDetails?.saleStatus === 'Finalised'
        ) {
            QuickSaleUpdate(
                vehicleSaleDetails.id,
                rows,
                showSnackbar,
                'vehicle'
            );
        } else if (
            serviceDetails?.id &&
            serviceDetails?.serviceComplete === 'Finalised'
        ) {
            QuickSaleUpdate(serviceDetails.id, rows, showSnackbar, 'service');
        }
    };

    return (
        <>
            {/* This line used to have payment.type.includes("Transfer") but I don't know what it is for. */}
            {/* Leaving comment so if something breaks it could be this */}

            {/* Inactive means split off, removed means removed by the user */}
            {/* {payment.inactive || payment.removed ?
                        <Typography variant="body2" sx={{ color: payment.inactive ? "#808080" : "" }}>{payment.type.toUpperCase()}</Typography>
                        : (payment.type != "directDeposit" && payment.date != moment().format("YYYY-MM-DD")) || payment.reconciled
                            ? payment.type.toUpperCase()
                            : <RoleCheck
                                permission="edit_salePayment"
                                component={
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        size="small"
                                        disabled={disabled || payment.type != "directDeposit" && payment.date != moment().format("YYYY-MM-DD")}
                                        select
                                        value={payment.type}
                                        onChange={(e) => updatePaymentType(payment.id, e.target.value)}
                                    >
                                        {settings?.paymentMethods?.map(method => {
                                            if (method.value === "account" && !payingCustomer?.debtor) {
                                                return null;
                                            } else {
                                                return <MenuItem value={method.value}>{method.name}</MenuItem>;
                                            }
                                        })}
                                    </TextField>
                                }
                                nullComponent={
                                    <Typography variant="body2" sx={{ color: payment.inactive ? "#808080" : "" }}>
                                        {payment.type.toUpperCase()}
                                    </Typography>
                                }
                            />
                    } */}
            {/* Leaving comment because above version worked fine, but user couldn't change till after finalising. Got complain about 
                    people sometimes putting the wrong till or the wrong payment method and have to fix them manually... Below version should let
                    them update Method if they have permission */}

            {payment.voucher ? (
                <DataCell>
                    <Typography
                        variant="body2"
                        sx={{ color: payment.inactive ? '#808080' : '' }}
                    >
                        Voucher <br />
                        <Typography
                            color="primary"
                            sx={{ cursor: 'pointer' }}
                            onClick={() =>
                                GetVoucher(
                                    payment.voucher,
                                    setVoucher,
                                    setVoucherDrawerOpen,
                                    showSnackbar
                                )
                            }
                        >
                            {payment.voucher}
                        </Typography>
                    </Typography>
                </DataCell>
            ) : (
                <DataCell>
                    {disabled ||
                    payment.reconciled ||
                    payment.inactive ||
                    payment.removed ? (
                        <Typography variant="body2">
                            {payment.type.toUpperCase()}
                        </Typography>
                    ) : isPaymentTillReconciled ? (
                        <RoleCheck
                            permission="admin_manager"
                            component={
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    select
                                    value={payment.type}
                                    onChange={(e) =>
                                        updatePaymentType(
                                            payment.id,
                                            e.target.value,
                                            payment.inactive
                                        )
                                    }
                                >
                                    {settings?.paymentMethods?.map((method) => {
                                        if (
                                            method.value === 'account' &&
                                            (!payingCustomer?.debtor ||
                                                (payment.inactive &&
                                                    payment.linkedSaleId >
                                                        saleDetails.id))
                                        ) {
                                            return null;
                                        } else {
                                            return (
                                                <MenuItem value={method.value}>
                                                    {method.name}
                                                </MenuItem>
                                            );
                                        }
                                    })}
                                </TextField>
                            }
                            nullComponent={
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: payment.inactive ? '#808080' : ''
                                    }}
                                >
                                    {payment.type.toUpperCase()}
                                </Typography>
                            }
                        />
                    ) : isFinalised ? (
                        <RoleCheck
                            permission="edit_salePayment"
                            component={
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    size="small"
                                    select
                                    value={payment.type}
                                    onChange={(e) =>
                                        updatePaymentType(
                                            payment.id,
                                            e.target.value,
                                            payment.inactive
                                        )
                                    }
                                >
                                    {settings?.paymentMethods?.map((method) => {
                                        if (
                                            method.value === 'account' &&
                                            (!payingCustomer?.debtor ||
                                                (payment.inactive &&
                                                    payment.linkedSaleId >
                                                        saleDetails.id))
                                        ) {
                                            return null;
                                        } else {
                                            return (
                                                <MenuItem value={method.value}>
                                                    {method.name}
                                                </MenuItem>
                                            );
                                        }
                                    })}
                                </TextField>
                            }
                            nullComponent={
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: payment.inactive ? '#808080' : ''
                                    }}
                                >
                                    {payment.type.toUpperCase()}
                                </Typography>
                            }
                        />
                    ) : (
                        <TextField
                            fullWidth
                            variant="standard"
                            size="small"
                            select
                            value={payment.type}
                            onChange={(e) =>
                                updatePaymentType(
                                    payment.id,
                                    e.target.value,
                                    payment.inactive
                                )
                            }
                        >
                            {settings?.paymentMethods?.map((method) => {
                                if (
                                    method.value === 'account' &&
                                    (!payingCustomer?.debtor ||
                                        (payment.inactive &&
                                            payment.linkedSaleId >
                                                saleDetails.id))
                                ) {
                                    return null;
                                } else {
                                    return (
                                        <MenuItem value={method.value}>
                                            {method.name}
                                        </MenuItem>
                                    );
                                }
                            })}
                        </TextField>
                    )}
                </DataCell>
            )}

            <Drawer
                openDrawer={voucherDrawerOpen}
                setOpenDrawer={setVoucherDrawerOpen}
                title={`Voucher Details`}
                subTitle={`Voucher Code: ${voucher?.voucherCode?.toUpperCase()}`}
                width="20vw"
            >
                <VoucherDrawerContent
                    voucher={voucher}
                    handleAddPayment={handleAddPayment}
                    hidePaymentAmount={true}
                />
            </Drawer>
        </>
    );
};

export default PaymentMethod;

import React from 'react';
import {
    Box,
    Divider,
    Table,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { CurrencyFormatter } from '../../../../../global/logic/Formatters';

export const InventoryOtherDrawerContent = ({
    selectedSite,
    queryDate,
    singleLine
}) => {
    return (
        <Box sx={{ width: '40vw', padding: '20px' }}>
            <Typography variant="h4">
                {typeof selectedSite != 'string' ? selectedSite.name : ''} -{' '}
                {queryDate.format('DD/MM/YYYY')}
            </Typography>
            <Typography>Inventory Stock Breakdown</Typography>
            <Box height={'10px'}></Box>
            <Divider></Divider>
            <Box height="20px"></Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <strong>Type</strong>
                        </TableCell>
                        <TableCell>
                            <strong>Amount</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableRow>
                    <TableCell>Stock Invoice</TableCell>
                    <TableCell>
                        {CurrencyFormatter(singleLine?.stockTemp)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Stock Transfer In</TableCell>
                    <TableCell>
                        {CurrencyFormatter(singleLine?.stockTransferToTotal)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Stock Adjustment</TableCell>
                    <TableCell>
                        {CurrencyFormatter(singleLine?.stockAdjustmentTotal)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>P&A Sales</TableCell>
                    <TableCell>
                        {CurrencyFormatter(-singleLine?.saleTemp)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Service Added Costs</TableCell>
                    <TableCell>
                        {CurrencyFormatter(-singleLine?.serviceTemp)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Stock Transfer Out</TableCell>
                    <TableCell>
                        {CurrencyFormatter(-singleLine?.stockTransferFromTotal)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Stock Invoice Credit</TableCell>
                    <TableCell>
                        {CurrencyFormatter(-singleLine?.creditInvoiceTotal)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Stocktake</TableCell>
                    <TableCell>
                        {CurrencyFormatter(singleLine?.stocktakeTotal)}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <b>TOTAL</b>
                    </TableCell>
                    <TableCell>
                        <b>{CurrencyFormatter(singleLine?.stockTotal)}</b>
                    </TableCell>
                </TableRow>
            </Table>
        </Box>
    );
};

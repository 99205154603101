import dayjs, { Dayjs } from 'dayjs';
import { Order } from '../../../../../global/interfaces/PartsInterface';
import { VehicleSale } from '../../../../../global/interfaces/VehicleSaleInterface';
import { Service } from '../../../../../global/interfaces/ServiceInterface';

const CalculateCustomerDepositBalanceSheet = (
    customerDepositData: {
        endDate: string;
        data: [Order[], VehicleSale[], Service[]][];
    }[],
    queryDate: Dayjs
) => {
    let paData = [];
    let vsData = [];
    let serviceData = [];

    for (let period of customerDepositData) {
        for (const [index, site] of period.data.entries()) {
            if (paData.length === index) {
                paData.push(site[0]);
                vsData.push(site[1]);
                serviceData.push(site[2]);
            } else {
                paData[index] = paData[index].concat(site[0]);
                vsData[index] = vsData[index].concat(site[1]);
                serviceData[index] = serviceData[index].concat(site[2]);
            }
        }
    }

    let paTotal = [];
    let vsTotal = [];
    let serviceTotal = [];

    for (const [index, site] of paData.entries()) {
        if (site.length === 0) {
            paTotal[index] = 0;
        } else {
            for (let sale of site) {
                if (paTotal.length === index) {
                    paTotal[index] = 0;
                }
                for (let line of sale.paymentLines) {
                    if (
                        dayjs(sale.finalisedAt).isAfter(queryDate) &&
                        dayjs(line.date, 'YYYY-MM-DD').isBefore(queryDate) &&
                        !line.removed
                    ) {
                        paTotal[index] += parseFloat(line.amount);
                    }
                }
            }
        }
    }

    for (const [index, site] of vsData.entries()) {
        if (site.length === 0) {
            vsTotal[index] = 0;
        } else {
            for (let sale of site) {
                if (vsTotal.length === index) {
                    vsTotal[index] = 0;
                }
                for (let line of sale.paymentLines) {
                    if (
                        dayjs(sale.finalisedDate).isAfter(queryDate) &&
                        dayjs(line.date, 'YYYY-MM-DD').isBefore(queryDate) &&
                        !line.removed
                    ) {
                        vsTotal[index] += parseFloat(line.amount);
                    }
                }
            }
        }
    }

    for (const [index, site] of serviceData.entries()) {
        if (site.length === 0) {
            serviceTotal[index] = 0;
        } else {
            for (let sale of site) {
                if (serviceTotal.length === index) {
                    serviceTotal[index] = 0;
                }
                for (let line of sale.paymentLines) {
                    if (
                        dayjs(sale.finalisedDate).isAfter(queryDate) &&
                        dayjs(line.date, 'YYYY-MM-DD').isBefore(queryDate) &&
                        !line.removed
                    ) {
                        serviceTotal[index] += parseFloat(line.amount);
                    }
                }
            }
        }
    }

    return [paTotal, vsTotal, serviceTotal, paData, vsData, serviceData];
};

export default CalculateCustomerDepositBalanceSheet;

// IMPORTS
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import moment from 'moment';
// COMPONENTS
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
// LOGIC
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
// INTERFACES
import { Order } from '../../../global/interfaces/PartsInterface';
import {
    Customer,
    PaymentLine
} from '../../../global/interfaces/GeneralInterface';
import InvoiceFooter from '../../../global/invoiceComponents/InvoiceFooter';

const PDFInvoice = async (
    SiteId: number,
    hasCustomer: boolean,
    paymentLines: PaymentLine[],
    saleDetails: Order,
    customerData: Customer,
    extraCosts: {
        id: number;
        type: string;
        amount: string;
        onlineVoucherCode?: string;
        VoucherId?: number;
        code?: string;
    }[],
    redirectId?: any,
    vouchers?
) => {
    try {
        var doc = new jsPDF({
            orientation: 'p',
            format: 'a4'
        });

        // Added more details for internal sales
        if (saleDetails.ServiceId) {
            saleDetails.PONumber = `${saleDetails.PONumber ? saleDetails.PONumber : 'None'} / Service ID: ${saleDetails.ServiceId}`;
        }
        if (saleDetails.VehicleId) {
            saleDetails.PONumber = `${saleDetails.PONumber ? saleDetails.PONumber : 'None'} / Vehicle Stock Number: ${saleDetails.Vehicle.stockNumber}`;
        }
        if (saleDetails.VehicleSaleId) {
            saleDetails.PONumber = `${saleDetails.PONumber ? saleDetails.PONumber : 'None'} / Vehicle Sale ID: ${saleDetails.VehicleSaleId}`;
        }

        // Table continaing the payment lines
        let header = [];
        if (saleDetails.displayRRP) {
            header = [
                [
                    'Qty',
                    'Part Number',
                    'Part Name',
                    'Unit RRP',
                    'Total\nEx.GST',
                    'GST',
                    'Line Total'
                ]
            ];
        } else {
            header = [
                [
                    'Qty',
                    'Part Number',
                    'Part Name',
                    'Total\nEx.GST',
                    'GST',
                    'Line Total'
                ]
            ];
        }

        let saleBody = [];
        let printAsTaxInvoice = false;
        let backorderBody = [];

        let saleTotal = 0;
        let backorderTotal = 0;

        saleDetails.orderLines.forEach((line) => {
            if (
                (!line.inactive && !line.linkedSaleId) ||
                (line.inactive && line.linkedSaleId)
            ) {
                if (parseInt(line.quantity) > 0) {
                    if (line.collecting) {
                        printAsTaxInvoice = true;
                    }
                    saleTotal +=
                        parseInt(line.quantity) * parseFloat(line.pricePaid);
                    let newRow;
                    if (saleDetails.displayRRP) {
                        newRow = [
                            line.quantity,
                            line.partNumber,
                            line.name,
                            CurrencyFormatter(line.priceRRP),
                            CurrencyFormatter(
                                (parseInt(line.quantity) *
                                    parseFloat(line.pricePaid)) /
                                    1.1
                            ),
                            CurrencyFormatter(
                                (parseInt(line.quantity) *
                                    parseFloat(line.pricePaid)) /
                                    11
                            ),
                            CurrencyFormatter(
                                parseInt(line.quantity) *
                                    parseFloat(line.pricePaid)
                            )
                        ];
                    } else {
                        newRow = [
                            line.quantity,
                            line.partNumber,
                            line.name,
                            CurrencyFormatter(
                                (parseInt(line.quantity) *
                                    parseFloat(line.pricePaid)) /
                                    1.1
                            ),
                            CurrencyFormatter(
                                (parseInt(line.quantity) *
                                    parseFloat(line.pricePaid)) /
                                    11
                            ),
                            CurrencyFormatter(
                                parseInt(line.quantity) *
                                    parseFloat(line.pricePaid)
                            )
                        ];
                    }
                    saleBody.push(newRow);
                }
                if (parseInt(line.backorderQuantity) > 0) {
                    backorderTotal +=
                        parseInt(line.backorderQuantity) *
                        parseFloat(line.pricePaid);
                    let newRow;
                    if (saleDetails.displayRRP) {
                        newRow = [
                            line.backorderQuantity,
                            line.partNumber,
                            line.name,
                            CurrencyFormatter(line.priceRRP),
                            CurrencyFormatter(
                                (parseInt(line.backorderQuantity) *
                                    parseFloat(line.pricePaid)) /
                                    1.1
                            ),
                            CurrencyFormatter(
                                (parseInt(line.backorderQuantity) *
                                    parseFloat(line.pricePaid)) /
                                    11
                            ),
                            CurrencyFormatter(
                                parseInt(line.backorderQuantity) *
                                    parseFloat(line.pricePaid)
                            )
                        ];
                    } else {
                        newRow = [
                            line.backorderQuantity,
                            line.partNumber,
                            line.name,
                            CurrencyFormatter(
                                (parseInt(line.backorderQuantity) *
                                    parseFloat(line.pricePaid)) /
                                    1.1
                            ),
                            CurrencyFormatter(
                                (parseInt(line.backorderQuantity) *
                                    parseFloat(line.pricePaid)) /
                                    11
                            ),
                            CurrencyFormatter(
                                parseInt(line.backorderQuantity) *
                                    parseFloat(line.pricePaid)
                            )
                        ];
                    }
                    backorderBody.push(newRow);
                }
            }
        });

        let invoiceTitle = '';
        if (saleDetails.OnlineOrderId) {
            invoiceTitle = `Online #${redirectId ? redirectId : saleDetails.id}`;
        } else if (
            (saleDetails.status === 'Proforma' && printAsTaxInvoice) ||
            saleDetails.status === 'Sale'
        ) {
            invoiceTitle = `Tax Invoice #${redirectId ? redirectId : saleDetails.id}`;
        } else if (saleDetails.status === 'Proforma') {
            invoiceTitle = `Proforma #${redirectId ? redirectId : saleDetails.id}`;
        } else {
            invoiceTitle = `Quote #${redirectId ? redirectId : saleDetails.id}`;
        }

        // Header for the invoice
        await InvoiceHeader(
            doc,
            SiteId,
            invoiceTitle,
            `${saleDetails.OnlineOrderId ? saleDetails.OnlineOrderId + ' - ' : ''}${DateFormatter(String(saleDetails.finalisedAt ? saleDetails.finalisedAt : new Date(Date.now())))}`,
            hasCustomer,
            true,
            true,
            customerData,
            saleDetails.User,
            saleDetails.finalisedAt,
            saleDetails.PONumber
        );

        let lastYcoordinates = 82;

        doc.setFont('helvetica', 'bold').setFontSize(12);

        if (saleBody.length > 0) {
            doc.text('Sale Lines ', 17, lastYcoordinates + 13);
            InvoiceTable(doc, lastYcoordinates + 18, header, saleBody);

            lastYcoordinates = (doc as any).lastAutoTable.finalY;
            let totalsContent = [];
            totalsContent.push([
                'Sub Total (Ex. GST)',
                CurrencyFormatter(saleTotal / 1.1)
            ]);
            totalsContent.push([
                'Total GST',
                CurrencyFormatter(saleTotal / 11)
            ]);

            autoTable(doc, {
                body: totalsContent,
                startY: lastYcoordinates + 3,
                theme: 'plain',
                margin: { bottom: 20, left: 145 },
                willDrawCell: function (data) {
                    var rows = data.table.body;
                    if (data.row.index === rows.length - 1) {
                        doc.setFont('helvetica', 'normal');
                    }
                }
            });
        }

        if (backorderBody.length > 0) {
            doc.text('Backorder Lines ', 17, lastYcoordinates + 30);
            InvoiceTable(doc, lastYcoordinates + 35, header, backorderBody);

            lastYcoordinates = (doc as any).lastAutoTable.finalY;
            let totalsContent = [];
            totalsContent.push([
                'Backorder Sub Total (Ex. GST)',
                CurrencyFormatter(backorderTotal / 1.1)
            ]);

            totalsContent.push([
                'Backorder Total GST',
                CurrencyFormatter(backorderTotal / 11)
            ]);

            autoTable(doc, {
                body: totalsContent,
                startY: lastYcoordinates + 3,
                theme: 'plain',
                margin: { bottom: 20, left: 140 },
                willDrawCell: function (data) {
                    var rows = data.table.body;
                    if (data.row.index === rows.length - 1) {
                        doc.setFont('helvetica', 'normal');
                    }
                }
            });
        }

        let voucherTotal = 0;
        let voucherHeader = [['Voucher ID', 'Voucher Code', 'Total Value']];
        let voucherBody = [];

        if (vouchers && vouchers.length > 0) {
            vouchers.forEach((line) => {
                voucherTotal += parseFloat(line.totalPrice);
                let newRow = [
                    line.id,
                    line.voucherCode,
                    CurrencyFormatter(line.totalPrice)
                ];
                voucherBody.push(newRow);
            });
        } else if (extraCosts.length > 0) {
            extraCosts.forEach((line) => {
                if (line.type === 'voucher') {
                    voucherTotal += parseFloat(line.amount);
                    let newRow = [
                        line.VoucherId,
                        line.code,
                        CurrencyFormatter(line.amount)
                    ];
                    voucherBody.push(newRow);
                }
            });
        }

        if (voucherTotal > 0) {
            doc.text('Vouchers ', 17, lastYcoordinates + 30);
            InvoiceTable(
                doc,
                lastYcoordinates + 35,
                voucherHeader,
                voucherBody
            );

            lastYcoordinates = (doc as any).lastAutoTable.finalY;
            let totalsContent = [];
            totalsContent.push([
                'Voucher Total',
                CurrencyFormatter(voucherTotal)
            ]);

            autoTable(doc, {
                body: totalsContent,
                startY: lastYcoordinates + 3,
                theme: 'plain',
                margin: { bottom: 20, left: 153 },
                willDrawCell: function (data) {
                    var rows = data.table.body;
                    if (data.row.index === rows.length - 1) {
                        doc.setFont('helvetica', 'normal');
                    }
                }
            });
        }

        lastYcoordinates = (doc as any).lastAutoTable.finalY;
        let totalsContent = [];
        if (saleDetails.freight > 0) {
            let freight = parseFloat(saleDetails.freight.toString());
            totalsContent.push(['Freight', CurrencyFormatter(freight)]);
            totalsContent.push([
                'Total (Inc. GST)',
                CurrencyFormatter(
                    saleTotal + backorderTotal + freight + voucherTotal
                )
            ]);
        } else {
            totalsContent.push([
                'Total (Inc. GST)',
                CurrencyFormatter(saleTotal + backorderTotal + voucherTotal)
            ]);
        }

        autoTable(doc, {
            body: totalsContent,
            startY: lastYcoordinates + 3,
            theme: 'plain',
            margin: { bottom: 20, left: 150 },
            styles: { halign: 'right' },
            willDrawCell: function (data) {
                var rows = data.table.body;
                if (data.row.index === rows.length - 1) {
                    doc.setFont('helvetica', 'bold');
                }
            }
        });
        lastYcoordinates = (doc as any).lastAutoTable.finalY;
        let onlyAccount = true;
        let accountPaymentPresent = false;

        if (paymentLines.length > 0) {
            let paymentHeader = [['Payment Method', 'Payment Date', 'Amount']];
            let paymentBody = [];

            paymentLines.forEach((line) => {
                if (line.type !== 'account') {
                    onlyAccount = false;
                    if (
                        (!line.removed &&
                            !line.inactive &&
                            !line.linkedSaleId) ||
                        (line.inactive && line.linkedSaleId)
                    ) {
                        let newRow = [
                            line.type.toUpperCase(),
                            `${DateFormatter(line.date)} ${moment(line.timestamp).format('h:mm A')}`,
                            CurrencyFormatter(line.amount)
                        ];
                        paymentBody.push(newRow);
                    }
                } else {
                    accountPaymentPresent = true;
                }
            });

            if (onlyAccount) {
                doc.text(
                    'Invoice charged to your account',
                    15,
                    lastYcoordinates + 5
                );
            } else if (!onlyAccount && accountPaymentPresent) {
                doc.text('Payment Lines ', 17, lastYcoordinates + 13);
                InvoiceTable(
                    doc,
                    lastYcoordinates + 18,
                    paymentHeader,
                    paymentBody
                );
                lastYcoordinates = (doc as any).lastAutoTable.finalY;
                doc.text(
                    'The remaining amount has been charged to your account',
                    15,
                    lastYcoordinates + 8
                );
            } else if (!onlyAccount && !accountPaymentPresent) {
                doc.text('Payment Lines ', 17, lastYcoordinates + 13);
                InvoiceTable(
                    doc,
                    lastYcoordinates + 18,
                    paymentHeader,
                    paymentBody
                );
            }
        }

        lastYcoordinates = (doc as any).lastAutoTable.finalY;
        if (saleDetails.customerNotes) {
            doc.text('Notes ', 17, lastYcoordinates + 13);
            let split = doc.splitTextToSize(saleDetails.customerNotes, 165);
            doc.setFont('helvetica', 'normal').setFontSize(10);
            doc.text(split, 17, lastYcoordinates + 20);

            let lineHeight = doc.getLineHeight() / doc.internal.scaleFactor;
            let nbOfLine = split.length;
            lastYcoordinates = lastYcoordinates + nbOfLine * lineHeight;
        }

        // lastYcoordinates += ;
        if (lastYcoordinates <= 270) {
            await InvoiceFooter(doc, SiteId);
        }

        // Open the pdf
        window.open(doc.output('bloburl'));
        return true;
    } catch (error) {
        return false;
    }
};

export default PDFInvoice;

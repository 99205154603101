import { TextField, Typography } from '@mui/material';
import { PaymentLine } from '../../interfaces/GeneralInterface';
import { CurrencyFormatter } from '../../logic/Formatters';
import DataCell from '../../tableComponents/DataCell';
import { Dispatch, SetStateAction } from 'react';
import RoleCheck from '../../RoleCheck';
import { Order } from '../../interfaces/PartsInterface';
import { VehicleSale } from '../../interfaces/VehicleSaleInterface';
import { Service } from '../../interfaces/ServiceInterface';
import QuickSaleUpdate from '../../../parts/newSale/logic/updateFunctions/QuickSaleUpdate';
import { showSnackbar } from '../../interfaces/GlobalInterface';

interface ReceivedAmountProps {
    payment: PaymentLine;
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    saleDetails: Order;
    vehicleSaleDetails: VehicleSale;
    serviceDetails: Service;
    showSnackbar: showSnackbar;
    disabled: boolean;
    isPaymentTillReconciled: boolean;
    isFinalised: boolean;
}

const ReceivedAmount = ({
    payment,
    paymentLines,
    setPaymentLines,
    saleDetails,
    vehicleSaleDetails,
    serviceDetails,
    showSnackbar,
    disabled,
    isPaymentTillReconciled,
    isFinalised
}: ReceivedAmountProps) => {
    const updateReceivedAmount = (e) => {
        const newAmount = parseFloat(e.target.value);
        const index = paymentLines.findIndex((x) => x.id === payment.id);
        let rows = [...paymentLines];
        let row = { ...rows[index] };

        row.amountReceived = newAmount;
        row.amount = newAmount;
        rows[index] = row;
        setPaymentLines(rows);
    };

    return (
        <DataCell textDecoration={payment.removed ? 'line-through' : ''}>
            {disabled ||
            payment.reconciled ||
            payment.inactive ||
            payment.removed ? (
                <Typography
                    variant="body2"
                    sx={{ color: payment.inactive ? '#808080' : '' }}
                >
                    {CurrencyFormatter(
                        Object.hasOwn(payment, 'amountReceived')
                            ? payment.amountReceived
                            : Object.hasOwn(payment, 'amount')
                              ? payment.amount
                              : payment.amountReceived
                    )}
                    {payment.type === 'cash' ? (
                        <>
                            <Typography
                                variant="body2"
                                sx={{ color: '#808080' }}
                            >
                                Change: {CurrencyFormatter(payment.change)}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ color: '#808080' }}
                            >
                                Tilled: {CurrencyFormatter(payment.amount)}
                            </Typography>
                        </>
                    ) : null}
                </Typography>
            ) : isPaymentTillReconciled ? (
                <RoleCheck
                    permission="admin_manager"
                    component={
                        <TextField
                            value={payment.amountReceived}
                            onChange={updateReceivedAmount}
                            variant="standard"
                            type="number"
                        />
                    }
                    nullComponent={
                        <Typography
                            variant="body2"
                            sx={{ color: payment.inactive ? '#808080' : '' }}
                        >
                            {CurrencyFormatter(
                                Object.hasOwn(payment, 'amountReceived')
                                    ? payment.amountReceived
                                    : Object.hasOwn(payment, 'amount')
                                      ? payment.amount
                                      : payment.amountReceived
                            )}
                            {payment.type === 'cash' ? (
                                <>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: '#808080' }}
                                    >
                                        Change:{' '}
                                        {CurrencyFormatter(payment.change)}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: '#808080' }}
                                    >
                                        Tilled:{' '}
                                        {CurrencyFormatter(payment.amount)}
                                    </Typography>
                                </>
                            ) : null}
                        </Typography>
                    }
                />
            ) : isFinalised ? (
                <RoleCheck
                    permission="edit_salePayment"
                    component={
                        <TextField
                            value={payment.amountReceived}
                            onChange={updateReceivedAmount}
                            variant="standard"
                            type="number"
                            inputProps={{ min: 0 }}
                        />
                    }
                    nullComponent={
                        <Typography
                            variant="body2"
                            sx={{ color: payment.inactive ? '#808080' : '' }}
                        >
                            {CurrencyFormatter(
                                Object.hasOwn(payment, 'amountReceived')
                                    ? payment.amountReceived
                                    : Object.hasOwn(payment, 'amount')
                                      ? payment.amount
                                      : payment.amountReceived
                            )}
                            {payment.type === 'cash' ? (
                                <>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: '#808080' }}
                                    >
                                        Change:{' '}
                                        {CurrencyFormatter(payment.change)}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ color: '#808080' }}
                                    >
                                        Tilled:{' '}
                                        {CurrencyFormatter(payment.amount)}
                                    </Typography>
                                </>
                            ) : null}
                        </Typography>
                    }
                />
            ) : (
                <TextField
                    value={payment.amountReceived}
                    onChange={updateReceivedAmount}
                    variant="standard"
                    type="number"
                />
            )}
        </DataCell>
    );
};

export default ReceivedAmount;
// IMPORTS
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItem,
    Typography,
    ListItemText
} from '@mui/material';
// COMPONENTS
import OrderlineHeader from './OrderlineComponents/OrderlineHeader';
import OrderlineTable from './OrderlineComponents/OrderlineTable';
import TotalForm from './OrderlineComponents/TotalForm';
import MiscellaneousCosts from './OrderlineComponents/MiscellaneousCosts';
// INTERFACES
import { Order, StockOrder } from '../../../global/interfaces/PartsInterface';
import {
    Customer,
    PaymentLine
} from '../../../global/interfaces/GeneralInterface';
import {
    Vehicle,
    VehicleSale
} from '../../../global/interfaces/VehicleSaleInterface';
import { Service } from '../../../global/interfaces/ServiceInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import GetServicePresetParts from '../logic/GetServicePresetParts';
import HandleAddPart from '../logic/HandleAddPart';

interface OrderlinesProps {
    id: number;
    readOnly: boolean;
    saleDetails: Order;
    setSaleDetails: Dispatch<SetStateAction<Order>>;
    oldSale: Order;
    selectedType: string;
    selectedEntity: Customer | VehicleSale | Vehicle | Service;
    extraCosts: { id: number; type: string; amount: string }[];
    setExtraCosts: Dispatch<
        SetStateAction<{ id: number; type: string; amount: string }[]>
    >;
    showSnackbar: showSnackbar;
    paymentLines: PaymentLine[];
    stockOrders: StockOrder[];
    setNotesDialogOpen: Dispatch<SetStateAction<boolean>>;
    selectedSite: number;
    setSelectedSite: Dispatch<SetStateAction<number>>;
}

const Orderlines = ({
    id,
    readOnly,
    saleDetails,
    setSaleDetails,
    oldSale,
    selectedType,
    selectedEntity,
    extraCosts,
    setExtraCosts,
    showSnackbar,
    paymentLines,
    stockOrders,
    setNotesDialogOpen,
    selectedSite,
    setSelectedSite
}: OrderlinesProps) => {
    const [presetPartsDialogOpen, setPresetPartsDialogOpen] = useState(false);
    const [groupedPresetParts, setGroupedPresetParts] = useState([]);

    const getServicePresetParts = async (jobCodeIds) => {
        const result = await GetServicePresetParts({
            jobCodeIds,
            SiteId: selectedSite
        });

        if (result && Object.keys(result).length > 0) {
            setGroupedPresetParts([
                ...Object.entries(result).map(([key, value]) => ({
                    serviceCode: key,
                    parts: value
                }))
            ]);
            setPresetPartsDialogOpen(true);
        }
    };

    useEffect(() => {
        if (
            !readOnly &&
            selectedEntity &&
            'jobCodes' in selectedEntity &&
            selectedEntity.jobCodes.length > 0
        ) {
            const jobCodeIds = selectedEntity.jobCodes.map(
                (jobCode) => jobCode.id
            );
            getServicePresetParts(jobCodeIds);
        }
        // eslint-disable-next-line
    }, [selectedEntity]);

    const handleConfirm = () => {
        const validParts = groupedPresetParts.flatMap((group) =>
            group.parts.filter((part) => !part.presetPartData.notFound)
        );

        let updatedSaleDetails = { ...saleDetails };

        for (const part of validParts) {
            updatedSaleDetails = HandleAddPart(
                part,
                updatedSaleDetails,
                setSaleDetails,
                selectedType,
                selectedEntity,
                part.supplierData
            );
        }

        setSaleDetails(updatedSaleDetails);
        setPresetPartsDialogOpen(false);
    };

    const handleCancel = () => {
        setPresetPartsDialogOpen(false);
    };

    return (
        <>
            <OrderlineHeader
                id={id}
                readOnly={readOnly}
                oldSale={oldSale}
                saleDetails={saleDetails}
                setSaleDetails={setSaleDetails}
                selectedType={selectedType}
                selectedEntity={selectedEntity}
                showSnackbar={showSnackbar}
                setNotesDialogOpen={setNotesDialogOpen}
                extraCosts={extraCosts}
                setExtraCosts={setExtraCosts}
                selectedSite={selectedSite}
                setSelectedSite={setSelectedSite}
            />
            <br />
            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <OrderlineTable
                        id={id}
                        readOnly={readOnly}
                        oldSale={oldSale}
                        saleDetails={saleDetails}
                        setSaleDetails={setSaleDetails}
                        selectedType={selectedType}
                        showSnackbar={showSnackbar}
                        stockOrders={stockOrders}
                    />
                    <br />
                    <MiscellaneousCosts
                        readOnly={readOnly || oldSale.status === 'Sale'}
                        saleDetails={saleDetails}
                        setSaleDetails={setSaleDetails}
                        extraCosts={extraCosts}
                        setExtraCosts={setExtraCosts}
                        selectedType={selectedType}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TotalForm
                        saleDetails={saleDetails}
                        extraCosts={extraCosts}
                        paymentLines={paymentLines}
                        selectedType={selectedType}
                    />
                </Grid>
            </Grid>
            <Dialog open={presetPartsDialogOpen} onClose={handleCancel}>
                <DialogTitle>Add Preset Parts</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        There are preset parts available for the job codes
                        associated with this service. Would you like to add
                        these parts to the orderlines?
                        <List dense>
                            {groupedPresetParts.map((group) => (
                                <React.Fragment key={group.serviceCode}>
                                    <ListItem disablePadding>
                                        <ListItemText
                                            primary={<b>{group.serviceCode}</b>}
                                        />
                                    </ListItem>
                                    {group.parts.map((part, index) => (
                                        <ListItem
                                            key={index}
                                            disablePadding
                                            style={{ paddingLeft: '1em' }}
                                        >
                                            <ListItemText>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <Typography
                                                            variant="body2"
                                                            color={
                                                                part
                                                                    .presetPartData
                                                                    .notFound
                                                                    ? 'error'
                                                                    : ''
                                                            }
                                                        >
                                                            {`${part.presetPartData?.partNumber} - ${part.supplierData?.name} x ${part.presetPartData.quantity} ${
                                                                part
                                                                    .presetPartData
                                                                    .notFound
                                                                    ? '- Part no longer exist, will not be added to orderlines'
                                                                    : ''
                                                            }`}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                    <br />
                                </React.Fragment>
                            ))}
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleConfirm}
                        color="primary"
                        variant="contained"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Orderlines;

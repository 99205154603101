// IMPORTS
import { ReactNode, useEffect, useState } from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import { Edit, Save } from '@mui/icons-material';
import MuiFab from '@mui/material/Fab';
import { useLocation } from 'react-router-dom';
// LOGIC
import socket from '../../../context/socket';

interface FabProps {
    editIcon?: boolean; // If no custom icon has been passed true will set an edit icon, false will set a save icon
    customIcon?: ReactNode; // Shows the icon sent
    reloadPage?: boolean; // Whether to reload the page upon a session change or not, defaults to false
    loading?: boolean; // Whether to replace the icon with a circular progress, defaults to false
    noSession?: boolean; // Handles whether to do the session checks, if true will not be session dependent, defaults to false
    onClick: any; // What to do when the fab is clicked, is dependent on the component
    label?: string; // Display a string within the tooltip, defaults to an empty string
    right?: number; // Optional: change position of the FAB
    bottom?: number; // Optional: change position of the FAB
    cypressLabel?: string; // Optional: change the cypress label for testing
}

const Fab = ({
    editIcon,
    customIcon,
    reloadPage,
    loading,
    noSession,
    onClick,
    label,
    right = 20,
    bottom = 20,
    cypressLabel
}: FabProps) => {
    const location = useLocation();
    const [disabled, setDisabled] = useState(false);
    const [message, setMessage] = useState('');

    const onClickInternal = (edit = true) => {
        if (!noSession) {
            socket.emit('edit', [edit, location.pathname], (cb) => {
                if (cb && reloadPage) {
                    window.location.reload();
                }
            });
        }
    };

    // Check if anyone is currently in the page
    useEffect(() => {
        if (!noSession) {
            socket.emit('check', location.pathname, (cb) => {
                setDisabled(cb[0]);
                setMessage(cb[1]);
            });
            socket.on(location.pathname, (data) => {
                if (data[0] === false && editIcon === undefined)
                    window.location.reload();
                if (data[1] === 'updated') window.location.reload();
                setDisabled(data[0]);
                setMessage(data[1]);
            });
            return () => {
                socket.off(location.pathname);
            };
        }
        // eslint-disable-next-line
    }, []);

    // Determine whether to show a close, save or edit icon in the fab
    const Icon = () => {
        if (customIcon) return customIcon;
        if (!editIcon) return <Save />;
        return <Edit />;
    };

    return (
        <Tooltip title={message}>
            <div
                style={{
                    margin: 0,
                    top: 'auto',
                    right: right,
                    bottom: bottom,
                    left: 'auto',
                    position: 'fixed',
                    zIndex: 1
                }}
            >
                <MuiFab
                    color="primary"
                    data-cy={cypressLabel ? cypressLabel : ''}
                    aria-label="add"
                    variant={label ? 'extended' : 'circular'}
                    onClick={(e) => [onClickInternal(editIcon), onClick()]}
                    disabled={loading ? loading || disabled : disabled}
                >
                    {loading ? <CircularProgress color="inherit" /> : Icon()}
                    {label ? label : ''}
                </MuiFab>
            </div>
        </Tooltip>
    );
};

export default Fab;

import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';

const GlobalQtyMovement = ({
    saleDetails,
    setSaleDetails,
    readOnly,
    oldSale
}) => {
    const moveAllToBackorders = () => {
        const updatedOrderLines = saleDetails.orderLines.map((line) => ({
            ...line,
            backorderQuantity: String(
                parseInt(line.quantity) + parseInt(line.backorderQuantity)
            ),
            quantity: '0'
        }));
        setSaleDetails({ ...saleDetails, orderLines: updatedOrderLines });
    };

    const moveAllToQuantities = () => {
        const updatedOrderLines = saleDetails.orderLines.map((line) => ({
            ...line,
            quantity: String(
                parseInt(line.quantity) + parseInt(line.backorderQuantity)
            ),
            backorderQuantity: '0'
        }));
        setSaleDetails({ ...saleDetails, orderLines: updatedOrderLines });
    };

    // check if all parts are in backorders
    const allInBackorder = saleDetails.orderLines.every(
        (line) => line.quantity === '0' && line.backorderQuantity !== '0'
    );

    const handleMoveAll = () => {
        if (allInBackorder) {
            moveAllToQuantities();
        } else {
            moveAllToBackorders();
        }
    };

    const isMoveToBackorderDisabled =
        readOnly ||
        (saleDetails.status === 'Sale' && oldSale.status === 'Sale');
    const isMoveToQuantityDisabled = readOnly || oldSale.status === 'Sale';

    return (
        <IconButton
            size="small"
            onClick={handleMoveAll}
            disabled={
                allInBackorder
                    ? isMoveToQuantityDisabled
                    : isMoveToBackorderDisabled
            }
        >
            {allInBackorder ? (
                <ArrowCircleLeft fontSize="small" />
            ) : (
                <ArrowCircleRight fontSize="small" />
            )}
        </IconButton>
    );
};

export default GlobalQtyMovement;

// IMPORTS
import { useEffect, useState } from 'react';
import { Typography, Grid, CircularProgress } from '@mui/material';

// LOGIC
import GetTrackingInformation from '../logic/GetTrackingInformation';
import { TimestampFormatter } from '../logic/Formatters';
import { showSnackbar } from '../interfaces/GlobalInterface';
import { OnlineOrder, TrackingInformation } from '../interfaces/PartsInterface';

interface TrackingProps {
    onlineOrderData: OnlineOrder;
    showSnackbar: showSnackbar;
}

const Tracking = ({ onlineOrderData, showSnackbar }: TrackingProps) => {
    const [trackingInformation, setTrackingInformation] =
        useState<TrackingInformation>({
            status: '',
            tracking_id: '',
            trackable_items: []
        });

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchTrackingInformation = async () => {
            if (onlineOrderData.ausPostShipmentId) {
                await GetTrackingInformation({
                    shipmentId: onlineOrderData.ausPostShipmentId,
                    setTrackingInformation,
                    setLoading,
                    showSnackbar
                });
            }
        };
        fetchTrackingInformation();
        // eslint-disable-next-line
    }, [onlineOrderData.ausPostShipmentId]);

    if (loading) {
        return <CircularProgress />;
    }
    return (
        <>
            <Typography variant="h6" align="left">
                Tracking Details
            </Typography>
            <br />
            {trackingInformation?.status === '' ? (
                <Typography variant="body1" align="center">
                    No Tracking Information Available
                    <br />
                </Typography>
            ) : (
                <>
                    <Typography variant="subtitle1" align="left">
                        <b>Current Status: {trackingInformation.status}</b>
                    </Typography>
                    <Typography variant="subtitle1" align="left">
                        Tracking Number: {trackingInformation.tracking_id}
                    </Typography>
                    <br />
                    <Grid container spacing={2}>
                        <Grid
                            item
                            xs={12}
                            style={{
                                overflowY: 'scroll',
                                maxHeight: '50vh'
                            }}
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <hr className="coloured-hr" />
                                </Grid>

                                {trackingInformation?.trackable_items[0]?.events?.map(
                                    (event) => (
                                        <>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    <b>Date: </b>{' '}
                                                    {TimestampFormatter(
                                                        event.date
                                                    )}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    <b>Description: </b>{' '}
                                                    {event.description}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    <b>Location: </b>{' '}
                                                    {event.location}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                                <hr className="coloured-hr" />
                                            </Grid>
                                        </>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default Tracking;

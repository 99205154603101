// IMPORTS
import jsPDF from 'jspdf';
// INTERFACES
import { Creditor } from '../../../global/interfaces/GeneralInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
// LOGIC
import InvoiceHeader from '../../../global/invoiceComponents/InvoiceHeader';
import InvoiceTable from '../../../global/invoiceComponents/InvoiceTable';
import { CalculateAmountRemaining } from './InvoiceSorting';
import {
    CurrencyFormatter,
    DateFormatter
} from '../../../global/logic/Formatters';
import GroupBy from '../../../global/logic/GroupBy';

interface CreditorLine {
    Creditor: Creditor;
    Invoices: any[];
    InvoiceTotal: number;
    PaymentTotal: number;
    PaymentRemaining: number;
}

interface CreditorReconciliationProps {
    creditors: CreditorLine[];
    showSnackbar: showSnackbar;
}

/**
 * GeneratePaymentRemittance
 * Generate a payment remittance for a creditor(s)
 * @author Estienne
 * @param creditors the creditor(s)
 */
const GeneratePaymentRemittance = ({
    creditors,
    showSnackbar
}: CreditorReconciliationProps) => {
    try {
        // Make a payment remittance for each creditor
        creditors.forEach(async (creditor) => {
            let groupedInvoices = GroupBy(creditor.Invoices, 'SiteId');

            groupedInvoices = groupedInvoices?.map((invoice) => {
                return {
                    Site: invoice[0].Site,
                    invoices: invoice
                };
            });

            groupedInvoices = groupedInvoices.filter((element) => {
                if (Object.keys(element).length !== 0) {
                    return true;
                }

                return false;
            });

            let doc = new jsPDF({
                orientation: 'p',
                format: 'a4'
            });

            // Set up the header
            await InvoiceHeader(
                doc,
                parseInt(localStorage.getItem('SiteId')),
                'Payment Remittance',
                DateFormatter(String(new Date(Date.now()))),
                false,
                true,
                false
            );

            // Set up the creditor's details in the header
            let lastYCoordinates = 50;
            doc.setFont('helvetica', 'bold');
            doc.text(creditor.Creditor.name, 15, lastYCoordinates + 5);
            doc.setFont('helvetica', 'normal');
            doc.text(
                `ABN: ${creditor.Creditor.abn}`,
                15,
                lastYCoordinates + 10
            );
            let addressLine1 = `${creditor.Creditor.addressLine1 ?? ''}`;
            let area = `${creditor.Creditor.suburb ?? ''} ${creditor.Creditor.postcode ?? ''} ${creditor.Creditor.state ?? ''}`;
            doc.text(addressLine1, 15, lastYCoordinates + 15);
            doc.text(area, 15, lastYCoordinates + 20);
            doc.text(
                `Phone: ${creditor.Creditor.phoneNumber}`,
                15,
                lastYCoordinates + 25
            );

            lastYCoordinates = lastYCoordinates + 40;

            groupedInvoices.forEach((group) => {
                doc.setFont('helvetica', 'bold').setFontSize(14);
                doc.text(group?.Site?.name, 15, lastYCoordinates);

                // Set up the table of invoices
                let header = [
                    [
                        'Invoice ID',
                        'Document Reference',
                        'Document Date',
                        'Invoice Amount',
                        'Payment Amount',
                        'Amount Remaining'
                    ]
                ];
                let body = [];
                let totalDocument = 0;
                let totalPaid = 0;
                let totalRemaining = 0;
                for (let invoice of group.invoices) {
                    let id = invoice.Invoice.id;
                    let reference = invoice.Invoice.documentReference;
                    let invoiceTotal = invoice.Invoice.documentTotal;
                    let invoiceDate = invoice?.Invoice?.invoiceDate
                        ? invoice?.Invoice?.invoiceDate
                        : invoice?.Invoice?.dateReceived
                          ? invoice?.Invoice?.dateReceived
                          : '';
                    let paymentAmount = invoice.paymentAmount;
                    let paymentRemaining = CalculateAmountRemaining(
                        parseFloat(invoiceTotal),
                        parseFloat(paymentAmount),
                        invoice.paymentHistory
                    );

                    totalDocument += parseFloat(invoiceTotal);
                    totalPaid += parseFloat(paymentAmount);
                    totalRemaining += CalculateAmountRemaining(
                        parseFloat(invoiceTotal),
                        parseFloat(paymentAmount),
                        invoice.paymentHistory
                    );

                    body.push([
                        id,
                        reference,
                        DateFormatter(invoiceDate),
                        CurrencyFormatter(invoiceTotal),
                        CurrencyFormatter(paymentAmount),
                        CurrencyFormatter(paymentRemaining)
                    ]);
                }

                body.push([
                    '',
                    '',
                    'Total:',
                    CurrencyFormatter(totalDocument),
                    CurrencyFormatter(totalPaid),
                    CurrencyFormatter(totalRemaining)
                ]);

                InvoiceTable(doc, lastYCoordinates + 5, header, body); // Create the table
                lastYCoordinates = (doc as any).lastAutoTable.finalY + 10;
            });

            window.open(doc.output('bloburl')); // Open the payment remittance
        });
    } catch (error) {
        showSnackbar(
            'Whoops! Something went wrong on our end.',
            'Please contact your IT department.',
            'error'
        );
    }
};

export default GeneratePaymentRemittance;

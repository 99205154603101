// LOGIC
import GenerateKawasakiOrderFile from './OrderFile/GenerateKawasakiOrderFile';
import GenerateHondaTXTOrder from './OrderFile/GenerateHondaTXTOrder';
import GenerateYamahaOrderFile from './OrderFile/GenerateYamahaOrderFile';
import GeneratePeterStevensOrderFile from './OrderFile/GeneratePeterStevensOrderFile';
// INTERFACES
import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Api } from '../../../global/interfaces/GeneralInterface';
import { Table } from '../interfaces/StockOrderInterface';

const GenerateOrderFilterRouter = (
    obj: { generator: Api; table: Table },
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    showSnackbar: showSnackbar
) => {
    if (obj.generator.OrderFileGeneratorId === 1) {
        GenerateHondaTXTOrder(obj, tables, setTables, showSnackbar);
    } else if (obj.generator.OrderFileGeneratorId === 2) {
        GenerateYamahaOrderFile(obj, tables, setTables, showSnackbar);
    } else if (obj.generator.OrderFileGeneratorId === 3) {
        GenerateKawasakiOrderFile(obj, tables, setTables, showSnackbar);
    } else if (obj.generator.OrderFileGeneratorId === 4) {
        GeneratePeterStevensOrderFile(obj, tables, setTables, showSnackbar);
    }
};

export default GenerateOrderFilterRouter;

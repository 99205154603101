import { Dispatch, SetStateAction } from 'react';
import api from '../../../../api';
import { showSnackbar } from '../interfaces/GlobalInterface';
import { TrackingInformation } from '../interfaces/PartsInterface';

interface GetTrackingInformationProps {
    shipmentId: number;
    setTrackingInformation: Dispatch<SetStateAction<TrackingInformation>>;
    setLoading: Dispatch<SetStateAction<boolean>>;
    showSnackbar: showSnackbar;
}

const GetTrackingInformation = async ({
    shipmentId,
    setTrackingInformation,
    setLoading,
    showSnackbar
}: GetTrackingInformationProps) => {
    setLoading(true);
    try {
        const res = await api.get(
            `trackingInformation/${shipmentId}?SiteId=${localStorage.getItem('SiteId')}`
        );
        if (res.status === 200) {
            setTrackingInformation(res.data.tracking_results[0]);
        } else {
            showSnackbar(
                'Error in GetTrackingInformation',
                res.data.error,
                'error'
            );
        }
    } catch (error) {
        showSnackbar('Error in GetTrackingInformation', error.message, 'error');
    } finally {
        setLoading(false);
    }
};

export default GetTrackingInformation;

const CalculateStockOnHandBalanceSheet = (
    otherInventoryData: {
        endDate: string;
        data: [
            number,
            number,
            number,
            number,
            number,
            number,
            number,
            number,
            number
        ][];
    }[]
) => {
    let calculatedInventoryData = [];
    for (let period of otherInventoryData) {
        for (let [index, site] of period.data.entries()) {
            if (calculatedInventoryData.length === index) {
                calculatedInventoryData.push({
                    stockTotal:
                        site[0] -
                        site[4] +
                        site[5] +
                        site[6] -
                        site[7] +
                        site[8],
                    stockInvoice: site[1],
                    saleTotal: site[2],
                    serviceTotal: site[3],
                    stockTransferFromTotal: site[4],
                    stockTransferToTotal: site[5],
                    stockAdjustmentTotal: site[6],
                    creditInvoiceTotal: site[7],
                    stocktakeTotal: site[8]
                });
            } else {
                calculatedInventoryData[index].stockTotal +=
                    site[0] - site[4] + site[5] + site[6] - site[7] + site[8];
                calculatedInventoryData[index].stockInvoice += site[1];
                calculatedInventoryData[index].saleTotal += site[2];
                calculatedInventoryData[index].serviceTotal += site[3];
                calculatedInventoryData[index].stockTransferFromTotal +=
                    site[4];
                calculatedInventoryData[index].stockTransferToTotal += site[5];
                calculatedInventoryData[index].stockAdjustmentTotal += site[6];
                calculatedInventoryData[index].creditInvoiceTotal += site[7];
                calculatedInventoryData[index].stocktakeTotal += site[8];
            }
        }
    }
    return calculatedInventoryData;
};
export default CalculateStockOnHandBalanceSheet;

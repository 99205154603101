import {
    Badge,
    Dialog,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from '@mui/material';
import Paper from '../../../global/Paper';
import {
    OnlineOrder,
    Order,
    StockOrder
} from '../../../global/interfaces/PartsInterface';
import {
    Inventory,
    MoveDown,
    ShoppingBag,
    StickyNote2
} from '@mui/icons-material';
import { Dispatch, SetStateAction, useState } from 'react';
import StockDialogContent from './StockDialogContent';
import NotesDialogContent from '../NotesDialogContent';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import BackordersDialogContent from './BackordersDialogContent';
import MoveDialogContent from './MoveDialogContent';
import { Site } from '../../../global/interfaces/GeneralInterface';
import { withSnackbar } from '../../../global/WrappingSnackbar';

interface GroupedOnlineOrders {
    onHold: GroupedOnlineOrder[];
    onBackorder: GroupedOnlineOrder[];
    newOrders: GroupedOnlineOrder[];
    pendingDispatch: GroupedOnlineOrder[];
}

interface GroupedOnlineOrder {
    oraOrder: Order;
    netoOrder: OnlineOrder;
    stockOrders: StockOrder[];
}

interface ViewOrdersProps {
    selectedGroupedOrder: GroupedOnlineOrder[];
    groupedOrders: GroupedOnlineOrders;
    setGroupedOrders: Dispatch<SetStateAction<GroupedOnlineOrders>>;
    handleUpdateAllStatesNotes: (
        newNotes: string,
        OrderId: any,
        onlineOrderNewNotes: string
    ) => void;
    handleUpdateAllStatesOrderlines: (
        newOrderLines,
        OrderId: any,
        moveOrder: boolean
    ) => void;
    handleUpdateAllStatesMovement: (
        newStatus: string,
        newSite: Site,
        OrderId: any
    ) => void;
    groupedTitle: string;
    showSnackbar: showSnackbar;
}

const ViewOrders = ({
    handleUpdateAllStatesMovement,
    handleUpdateAllStatesOrderlines,
    groupedTitle,
    selectedGroupedOrder,
    groupedOrders,
    setGroupedOrders,
    handleUpdateAllStatesNotes,
    showSnackbar
}: ViewOrdersProps) => {
    const [selectedOrder, setSelectedOrder] = useState<GroupedOnlineOrder>();
    const [stockDialogOpen, setStockDialogOpen] = useState<boolean>(false);
    const [notesDialogOpen, setNotesDialogOpen] = useState<boolean>(false);
    const [backordersDialogOpen, setBackordersDialogOpen] =
        useState<boolean>(false);
    const [moveDialogOpen, setMoveDialogOpen] = useState<boolean>(false);

    const handleOpenStockDialog = (order: GroupedOnlineOrder) => {
        setSelectedOrder(order);
        setStockDialogOpen(true);
    };

    const handleOpenNotesDialog = (order: GroupedOnlineOrder) => {
        setSelectedOrder(order);
        setNotesDialogOpen(true);
    };

    const handleOpenBackorderDialog = (order: GroupedOnlineOrder) => {
        setSelectedOrder(order);
        setBackordersDialogOpen(true);
    };

    const handleOpenMoveDialog = (order: GroupedOnlineOrder) => {
        setSelectedOrder(order);
        setMoveDialogOpen(true);
    };

    const handleCloseBackorderDialog = () => {
        let currentOrderLines = [...selectedOrder?.oraOrder?.orderLines];
        currentOrderLines.forEach((line) => {
            line.backorderQuantity = '0';
        });

        let currentOraOrder = selectedOrder.oraOrder;
        currentOraOrder.orderLines = currentOrderLines;
        setSelectedOrder({ ...selectedOrder, oraOrder: currentOraOrder });

        setBackordersDialogOpen(false);
    };

    const checkOrderForBackorders = (orderLines) => {
        let backorderLines = orderLines.filter(
            (x) => parseInt(x.backorderQuantity) > 0
        );

        // If there are backorders or we are on the pending dispatch screen, we don't want
        // the user to be able to place backorders
        if (backorderLines.length > 0 || groupedTitle === 'Pending Dispatch') {
            return true;
        } else {
            return false;
        }
    };

    const findStockOrderId = (stockOrders, oraOrderLine, oraOrder) => {
        let stockOrderId = null;
        let invoiced = false;

        stockOrders.forEach((stockOrder) => {
            let filteredLine = stockOrder.orderLines.filter(
                (x) => x.id === oraOrderLine.UnitId && x.orderId === oraOrder.id
            );

            if (filteredLine.length > 0) {
                stockOrderId = stockOrder.id;
                invoiced = stockOrder.invoiced;
            }
        });
        return { stockOrderId: stockOrderId, invoiced: invoiced };
    };

    return (
        <>
            {selectedGroupedOrder?.map((order) => (
                <>
                    <Paper>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography
                                    variant="subtitle1"
                                    onClick={() =>
                                        window.open(
                                            `/inventory/viewSale/${order.oraOrder.id}`,
                                            '_blank'
                                        )
                                    }
                                >
                                    <b>
                                        {order?.netoOrder?.OnlineOrderId} |{' '}
                                        {order?.netoOrder?.shippingFirstName}{' '}
                                        {order?.netoOrder?.shippingLastName}
                                    </b>
                                </Typography>
                                <Typography variant="subtitle2">
                                    {order?.oraOrder?.Site?.name} |{' '}
                                    {order?.netoOrder?.shippingOption}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} textAlign="right">
                                <Tooltip title="Move Order" placement="top">
                                    <IconButton
                                        onClick={() =>
                                            handleOpenMoveDialog(order)
                                        }
                                    >
                                        <MoveDown />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Order Notes" placement="top">
                                    <IconButton
                                        onClick={() =>
                                            handleOpenNotesDialog(order)
                                        }
                                    >
                                        <Badge
                                            variant="dot"
                                            color="primary"
                                            badgeContent=" "
                                            invisible={
                                                order.oraOrder.notes
                                                    ? false
                                                    : true
                                            }
                                        >
                                            <StickyNote2 />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="View Stock" placement="top">
                                    <IconButton
                                        onClick={() =>
                                            handleOpenStockDialog(order)
                                        }
                                    >
                                        <Inventory />
                                    </IconButton>
                                </Tooltip>
                                {!checkOrderForBackorders(
                                    order.oraOrder.orderLines
                                ) ? (
                                    <Tooltip
                                        title="Create Backorders"
                                        placement="top"
                                    >
                                        <IconButton
                                            onClick={() =>
                                                handleOpenBackorderDialog(order)
                                            }
                                        >
                                            <ShoppingBag />
                                        </IconButton>
                                    </Tooltip>
                                ) : null}
                            </Grid>
                        </Grid>
                        <br />
                        {order.oraOrder.orderLines.map((line) => (
                            <>
                                {parseInt(line.backorderQuantity) > 0 &&
                                !backordersDialogOpen
                                    ? (() => {
                                          const { stockOrderId, invoiced } =
                                              findStockOrderId(
                                                  order.stockOrders,
                                                  line,
                                                  order.oraOrder
                                              );

                                          if (stockOrderId) {
                                              return (
                                                  <Typography
                                                      variant="body1"
                                                      sx={{ color: 'blue' }}
                                                  >
                                                      <b>
                                                          SO #{stockOrderId}
                                                          {': '}
                                                          {
                                                              line.backorderQuantity
                                                          }{' '}
                                                          x {line.partNumber}
                                                      </b>{' '}
                                                      | {line.name}
                                                  </Typography>
                                              );
                                          }
                                          return (
                                              <Typography
                                                  variant="body1"
                                                  sx={{ color: 'orangered' }}
                                              >
                                                  <b>
                                                      BACKORDER:{' '}
                                                      {line.backorderQuantity} x{' '}
                                                      {line.partNumber}
                                                  </b>{' '}
                                                  | {line.name}
                                              </Typography>
                                          );
                                      })()
                                    : null}
                                {parseInt(line.quantity) > 0 &&
                                parseInt(line.backorderQuantity) === 0 ? (
                                    line.stockOnHand <
                                        parseInt(line.quantity) &&
                                    groupedTitle === 'New Orders' ? (
                                        <Typography
                                            variant="body1"
                                            sx={{ color: 'orangered' }}
                                        >
                                            <b>
                                                {line.quantity} x{' '}
                                                {line.partNumber}
                                            </b>{' '}
                                            | {line.name} | Need to backorder:{' '}
                                            {line.stockOnHand < 0
                                                ? line.quantity
                                                : Math.abs(
                                                      line.stockOnHand -
                                                          parseInt(
                                                              line.quantity
                                                          )
                                                  )}
                                        </Typography>
                                    ) : (
                                        <Typography variant="body1">
                                            <b>
                                                {line.quantity} x{' '}
                                                {line.partNumber}
                                            </b>{' '}
                                            | {line.name}
                                        </Typography>
                                    )
                                ) : null}
                            </>
                        ))}
                    </Paper>
                    <br />
                </>
            ))}

            <Dialog
                open={stockDialogOpen}
                onClose={() => setStockDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <StockDialogContent selectedOrder={selectedOrder} />
            </Dialog>

            <Dialog
                open={notesDialogOpen}
                onClose={() => setNotesDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <NotesDialogContent
                    handleUpdateAllStatesNotes={handleUpdateAllStatesNotes}
                    selectedOrder={selectedOrder}
                    setNotesDialogOpen={setNotesDialogOpen}
                    showSnackbar={showSnackbar}
                />
            </Dialog>

            <Dialog
                open={backordersDialogOpen}
                onClose={() => handleCloseBackorderDialog()}
                maxWidth="md"
                fullWidth
            >
                <BackordersDialogContent
                    handleCloseBackorderDialog={handleCloseBackorderDialog}
                    handleUpdateAllStatesOrderlines={
                        handleUpdateAllStatesOrderlines
                    }
                    showSnackbar={showSnackbar}
                    selectedOrder={selectedOrder}
                    setSelectedOrder={setSelectedOrder}
                    setBackordersDialogOpen={setBackordersDialogOpen}
                />
            </Dialog>

            <Dialog
                open={moveDialogOpen}
                onClose={() => setMoveDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <MoveDialogContent
                    handleUpdateAllStatesMovement={
                        handleUpdateAllStatesMovement
                    }
                    selectedOrder={selectedOrder}
                    showSnackbar={showSnackbar}
                    setMoveDialogOpen={setMoveDialogOpen}
                />
            </Dialog>
        </>
    );
};

export default withSnackbar(ViewOrders);

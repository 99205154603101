import api from '../../../../../../../api';
import CombineBalanceSheetLines from './CombineBalanceSheetLines';
import { BalanceSheetLine } from '../interface/BalanceSheetInterface';
import dayjs, { Dayjs } from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import CalculateVehicleStockBalanceSheet from './CalculateVehicleStockBalanceSheet';
import CalculateStockOnHandBalanceSheet from './CalculateStockOnHandBalanceSheet';
import CalculateFloorplanLiabilityBalanceSheet from './CalculateFloorplanLiabilityBalanceSheet';
import CalculateDebtorPaymentBalanceSheet from './CalculateDebtorPaymentBalanceSheet';
import { DebtorPayment } from '../../../../../global/interfaces/GeneralInterface';
import CalculateCustomerDepositBalanceSheet from './CalculateCustomerDepositBalanceSheet';
import { showSnackbar } from '../../../../../global/interfaces/GlobalInterface';
import { VehicleSale } from '../../../../../global/interfaces/VehicleSaleInterface';
import { Order } from '../../../../../global/interfaces/PartsInterface';
import { Service } from '../../../../../global/interfaces/ServiceInterface';

const CalculateBalanceSheet = async (
    queryDate: Dayjs,
    setAllLines: Dispatch<SetStateAction<BalanceSheetLine[]>>,
    setShowSiteSelector: Dispatch<SetStateAction<boolean>>,
    setSingleLine: Dispatch<SetStateAction<BalanceSheetLine>>,
    setLoading: Dispatch<SetStateAction<boolean>>,
    setProgress: Dispatch<SetStateAction<number>>,
    setProgressDetails: Dispatch<SetStateAction<string[]>>,
    showSnackbar: showSnackbar
) => {
    setLoading(true);
    let progressArray = [];

    let start = dayjs('01/07/2020', 'DD/MM/YYYY').startOf('day');
    let end = dayjs('30/06/2021', 'DD/MM/YYYY').endOf('day');
    let dateArray = [[start.toISOString(), end.toISOString()]];
    while (end.isBefore(queryDate)) {
        start = end.clone().add(1, 'day').startOf('day');
        end = start.clone().add(1, 'year').date(30).month(5).endOf('day');
        if (end.isAfter(queryDate)) {
            dateArray.push([
                start.toISOString(),
                queryDate.endOf('day').toISOString()
            ]);
        } else {
            dateArray.push([start.toISOString(), end.toISOString()]);
        }
    }

    let vehicleInventoryPromises = [];
    let vehicleInventoryData: {
        endDate: string;
        data:
            | [
                  number,
                  { id: number; cost: number }[],
                  number,
                  { id: number; cost: number }[]
              ][]
            | [
                  number,
                  { id: number; cost: number }[],
                  number,
                  { id: number; cost: number }[],
                  number[]
              ][];
    }[] = [];

    let otherInventoryPromises = [];
    let otherInventoryData: {
        endDate: string;
        data: [
            number,
            number,
            number,
            number,
            number,
            number,
            number,
            number,
            number
        ][];
    }[] = [];

    let floorplanPromises = [];
    let floorplanData: [number, { id: number; cost: number }[], number[]][] =
        [];

    let debtorPaymentPromises = [];
    let debtorPaymentData: {
        endDate: string;
        data: [number, DebtorPayment[]];
    }[] = [];

    let customerDepositPromises = [];
    let customerDepositData: {
        endDate: string;
        data: [Order[], VehicleSale[], Service[]][];
    }[] = [];

    let pendingPayrunData = [];

    let accountPromises = [];
    let accountData: {
        endDate: string;
        data: {
            AccountId: number;
            accountName: string;
            assetType: string;
            initialBalance?: number;
            total: number;
            transactions: {
                amount: number;
                date: string;
                id: string;
                reference: string;
                url: string;
                user: string;
                paymentHistory: {
                    date: string;
                    bankRecDate: string;
                    BankReconciliationId: number;
                    PaymentRunId: number;
                    amount: number;
                    reconciled: boolean;
                }[];
            }[];
        }[][];
    }[] = [];

    let balanceSheetLines: BalanceSheetLine[] = [];

    for (let interval of dateArray) {
        vehicleInventoryPromises.push(
            api
                .get(
                    `balanceSheetNextGenInventoryVehicle?startDate=${interval[0]}&endDate=${interval[1]}`
                )
                .then((res) => {
                    if (res.status === 200) {
                        vehicleInventoryData.push({
                            endDate: interval[1],
                            data: res.data
                        });
                    } else if (res.status === 500) {
                        setLoading(false);
                        showSnackbar(
                            'Something went wrong and the data could not be retrieved',
                            'Please contact the Ora DMS team.',
                            'error'
                        );
                    }
                })
        );
    }
    Promise.all(vehicleInventoryPromises).then(() => {
        // first order the received data by date
        vehicleInventoryData.sort((a, b) =>
            dayjs(a.endDate).isBefore(dayjs(b.endDate)) ? -1 : 1
        );

        const [newVehicleTotal, usedVehicleTotal] =
            CalculateVehicleStockBalanceSheet(vehicleInventoryData);
        setProgress((1 / 8) * 100);
        progressArray.push('Vehicle');
        setProgressDetails(progressArray);

        for (let i = 0; i < newVehicleTotal.length; i++) {
            balanceSheetLines.push({
                SiteId: i + 1,
                cashAtBankTotal: 0,
                newVehiclesTotal: newVehicleTotal[i],
                newVehiclesData: [],
                usedVehiclesTotal: usedVehicleTotal[i],
                usedVehiclesData: [],
                floorplanLiabilityTotal: 0,
                floorplanLiabilityData: [],
                stockAdjustmentTotal: 0,
                stockAdjustmentData: [],
                averageCostAdjustmentTotal: 0,
                averageCostAdjustmentData: [],
                stripeFeesTotal: 0,
                stripeFeesData: [],
                rebateBalanceTotal: 0,
                rebateBalanceData: [],
                registrationClearingTotal: 0,
                registrationClearingData: [],
                accountLines: [],
                cashHoldingTotal: 0,
                stockOnHandTotal: 0,
                pendingDebtorPayment: 0,
                debtorPaymentData: [],
                giftVoucherData: [],
                giftVoucher: 0,
                pendingPayrunData: [],
                pendingPayrun: 0,
                paymentLiabilityPA: 0,
                paymentLiabilityVehicleSale: 0,
                paymentLiabilityService: 0,
                paymentLiabilityDataPA: [],
                paymentLiabilityDataVehicleSale: [],
                paymentLiabilityDataService: []
            });
        }

        for (let interval of dateArray) {
            otherInventoryPromises.push(
                api
                    .get(
                        `balanceSheetNextGenInventoryOther?startDate=${interval[0]}&endDate=${interval[1]}`
                    )
                    .then((res) => {
                        if (res.status === 200) {
                            otherInventoryData.push({
                                endDate: interval[1],
                                data: res.data
                            });
                        } else if (res.status === 500) {
                            setLoading(false);
                            showSnackbar(
                                'Something went wrong and the data could not be retrieved',
                                'Please contact the Ora DMS team.',
                                'error'
                            );
                        }
                    })
            );
        }
        Promise.all(otherInventoryPromises).then(() => {
            let calculatedInventoryData =
                CalculateStockOnHandBalanceSheet(otherInventoryData);
            for (let i = 0; i < calculatedInventoryData.length; i++) {
                balanceSheetLines[i].stockOnHandTotal =
                    calculatedInventoryData[i].stockTotal;
            }
            setProgress((2 / 8) * 100);
            progressArray.push('Stock');
            setProgressDetails(progressArray);

            for (let interval of dateArray) {
                floorplanPromises.push(
                    api
                        .get(
                            `balanceSheetNextGenFloorplanLiability?startDate=${interval[0]}&endDate=${interval[1]}`
                        )
                        .then((res) => {
                            if (res.status === 200) {
                                floorplanData.push(res.data);
                            } else if (res.status === 500) {
                                setLoading(false);
                                showSnackbar(
                                    'Something went wrong and the data could not be retrieved',
                                    'Please contact the Ora DMS team.',
                                    'error'
                                );
                            }
                        })
                );
            }
            Promise.all(floorplanPromises).then(() => {
                let floorplanLiabilityTotal =
                    CalculateFloorplanLiabilityBalanceSheet(floorplanData);
                for (let i = 0; i < floorplanLiabilityTotal.length; i++) {
                    balanceSheetLines[i].floorplanLiabilityTotal =
                        floorplanLiabilityTotal[i];
                }

                setProgress((3 / 8) * 100);
                progressArray.push('Floorplan');
                setProgressDetails(progressArray);

                for (let interval of dateArray) {
                    debtorPaymentPromises.push(
                        api
                            .get(
                                `balanceSheetNextGenDebtorPayment?startDate=${interval[0]}&endDate=${interval[1]}`
                            )
                            .then((res) => {
                                if (res.status === 200) {
                                    debtorPaymentData.push({
                                        endDate: interval[1],
                                        data: res.data
                                    });
                                } else if (res.status === 500) {
                                    setLoading(false);
                                    showSnackbar(
                                        'Something went wrong and the data could not be retrieved',
                                        'Please contact the Ora DMS team.',
                                        'error'
                                    );
                                }
                            })
                    );
                }
                Promise.all(debtorPaymentPromises).then(() => {
                    let [debtorPaymentTotalPerSite, debtorPaymentDataPerSite] =
                        CalculateDebtorPaymentBalanceSheet(debtorPaymentData);

                    for (let i = 0; i < debtorPaymentTotalPerSite.length; i++) {
                        balanceSheetLines[i].pendingDebtorPayment =
                            debtorPaymentTotalPerSite[i];
                        balanceSheetLines[i].debtorPaymentData =
                            debtorPaymentDataPerSite[i];
                    }

                    setProgress((4 / 8) * 100);
                    progressArray.push('Debtor');
                    setProgressDetails(progressArray);

                    for (let interval of dateArray) {
                        customerDepositPromises.push(
                            api
                                .get(
                                    `balanceSheetNextGenCustomerDeposit?startDate=${interval[0]}&endDate=${interval[1]}`
                                )
                                .then((res) => {
                                    if (res.status === 200) {
                                        customerDepositData.push({
                                            endDate: interval[1],
                                            data: res.data
                                        });
                                    } else if (res.status === 500) {
                                        setLoading(false);
                                        showSnackbar(
                                            'Something went wrong and the data could not be retrieved',
                                            'Please contact the Ora DMS team.',
                                            'error'
                                        );
                                    }
                                })
                        );
                    }
                    Promise.all(customerDepositPromises).then(() => {
                        const [
                            paTotal,
                            vsTotal,
                            serviceTotal,
                            paData,
                            vsData,
                            serviceData
                        ] = CalculateCustomerDepositBalanceSheet(
                            customerDepositData,
                            queryDate
                        );

                        for (let i = 0; i < paTotal.length; i++) {
                            balanceSheetLines[i].paymentLiabilityPA =
                                paTotal[i];
                            balanceSheetLines[i].paymentLiabilityVehicleSale =
                                vsTotal[i];
                            balanceSheetLines[i].paymentLiabilityService =
                                serviceTotal[i];
                            balanceSheetLines[i].paymentLiabilityDataPA =
                                paData[i];
                            balanceSheetLines[
                                i
                            ].paymentLiabilityDataVehicleSale = vsData[i];
                            balanceSheetLines[i].paymentLiabilityDataService =
                                serviceData[i];
                        }

                        setProgress((5 / 8) * 100);
                        progressArray.push('Deposit');
                        setProgressDetails(progressArray);

                        api.get(
                            `balanceSheetNextGenPendingPayrun?date=${queryDate}`
                        ).then((res) => {
                            if (res.status === 200) {
                                setProgress((6 / 8) * 100);
                                progressArray.push('Payrun');
                                setProgressDetails(progressArray);
                                pendingPayrunData = res.data;
                                balanceSheetLines[
                                    vehicleInventoryData.length - 1
                                ].pendingPayrunData = pendingPayrunData[1];
                                balanceSheetLines[
                                    vehicleInventoryData.length - 1
                                ].pendingPayrun = pendingPayrunData[0];
                            } else if (res.status === 500) {
                                setLoading(false);
                                showSnackbar(
                                    'Something went wrong and the data could not be retrieved',
                                    'Please contact the Ora DMS team.',
                                    'error'
                                );
                            }

                            for (let interval of dateArray) {
                                accountPromises.push(
                                    api
                                        .get(
                                            `balanceSheetNextGenAccounts?startDate=${interval[0]}&endDate=${interval[1]}`
                                        )
                                        .then((res) => {
                                            if (res.status === 200) {
                                                accountData.push({
                                                    endDate: interval[1],
                                                    data: res.data
                                                });
                                            } else if (res.status === 500) {
                                                setLoading(false);
                                                showSnackbar(
                                                    'Something went wrong and the data could not be retrieved',
                                                    'Please contact the Ora DMS team.',
                                                    'error'
                                                );
                                            }
                                        })
                                );
                            }

                            Promise.all(accountPromises).then(() => {
                                setProgress((7 / 8) * 100);
                                progressArray.push('Accounts');
                                setProgressDetails(progressArray);
                                let accountPerSite = [];
                                for (let period of accountData) {
                                    for (let [
                                        index,
                                        site
                                    ] of period.data.entries()) {
                                        if (accountPerSite.length === index) {
                                            accountPerSite.push(site);
                                        } else {
                                            for (let account of site) {
                                                let accountIndex =
                                                    accountPerSite[
                                                        index
                                                    ].findIndex(
                                                        (x) =>
                                                            x.AccountId ===
                                                            account.AccountId
                                                    );
                                                if (accountIndex === -1) {
                                                    accountPerSite[index].push(
                                                        account
                                                    );
                                                } else {
                                                    accountPerSite[index][
                                                        accountIndex
                                                    ].transactions =
                                                        accountPerSite[index][
                                                            accountIndex
                                                        ].transactions.concat(
                                                            account.transactions
                                                        );
                                                }
                                            }
                                        }
                                    }
                                }
                                for (let [
                                    i,
                                    site
                                ] of accountPerSite.entries()) {
                                    balanceSheetLines[i].accountLines = site;
                                }

                                api.get(
                                    `balanceSheetNextGenGiftVoucher?date=${queryDate}`
                                ).then((res) => {
                                    if (res.status === 200) {
                                        setProgress(100);
                                        progressArray.push('GiftVoucher');
                                        setProgressDetails(progressArray);

                                        balanceSheetLines[
                                            balanceSheetLines.length - 1
                                        ].giftVoucherData = res.data[1];
                                        balanceSheetLines[
                                            balanceSheetLines.length - 1
                                        ].giftVoucher = res.data[0];

                                        setShowSiteSelector(true);
                                        setLoading(false);
                                        setAllLines(balanceSheetLines);

                                        CombineBalanceSheetLines(
                                            balanceSheetLines,
                                            setSingleLine
                                        );
                                    } else if (res.status === 500) {
                                        setLoading(false);
                                        showSnackbar(
                                            'Something went wrong and the data could not be retrieved',
                                            'Please contact the Ora DMS team.',
                                            'error'
                                        );
                                    }
                                });
                            });
                        });
                    });
                });
            });
        });
    });

    // api.get(`balanceSheetNextGenInventoryVehicle?date=${queryDate}`).then(
    //     (res) => {
    //         counter += 1;
    //         setProgress((counter / 8) * 100);
    //         progressArray.push('Vehicle');
    //         setProgressDetails(progressArray);
    //         vehicleData = res.data;
    //         api.get(`balanceSheetNextGenInventoryOther?date=${queryDate}`).then(
    //             (res) => {
    //                 counter += 1;
    //                 setProgress((counter / 8) * 100);
    //                 progressArray.push('Stock');
    //                 setProgressDetails(progressArray);
    //                 inventoryOther = res.data;
    //                 api.get(
    //                     `balanceSheetNextGenFloorplanLiability?date=${queryDate}`
    //                 ).then((res) => {
    //                     counter += 1;
    //                     setProgress((counter / 8) * 100);
    //                     progressArray.push('Floorplan');
    //                     setProgressDetails(progressArray);
    //                     floorplanLiability = res.data;
    //                     api.get(
    //                         `balanceSheetNextGenDebtorPayment?date=${queryDate}`
    //                     ).then((res) => {
    //                         counter += 1;
    //                         setProgress((counter / 8) * 100);
    //                         progressArray.push('Debtor');
    //                         setProgressDetails(progressArray);
    //                         debtorPayment = res.data;
    //                         api.get(
    //                             `balanceSheetNextGenCustomerDeposit?date=${queryDate}`
    //                         ).then((res) => {
    //                             counter += 1;
    //                             setProgress((counter / 8) * 100);
    //                             progressArray.push('Deposit');
    //                             setProgressDetails(progressArray);
    //                             customerDeposit = res.data;
    //                             api.get(
    //                                 `balanceSheetNextGenPendingPayrun?date=${queryDate}`
    //                             ).then((res) => {
    //                                 counter += 1;
    //                                 setProgress((counter / 8) * 100);
    //                                 progressArray.push('Payrun');
    //                                 setProgressDetails(progressArray);
    //                                 pendingPayrun = res.data;
    //                                 api.get(
    //                                     `balanceSheetNextGenAccounts?date=${queryDate}`
    //                                 ).then((res) => {
    //                                     counter += 1;
    //                                     setProgress((counter / 8) * 100);
    //                                     progressArray.push('Accounts');
    //                                     setProgressDetails(progressArray);
    //                                     accounts = res.data;
    //                                     api.get(
    //                                         `balanceSheetNextGenGiftVoucher?date=${queryDate}`
    //                                     ).then((res) => {
    //                                         counter += 1;
    //                                         setProgress((counter / 8) * 100);
    //                                         progressArray.push('GiftVoucher');
    //                                         setProgressDetails(progressArray);
    //                                         giftVoucher = res.data;

    //                                         let balanceSheetLines = [];

    //                                         for (
    //                                             let i = 0;
    //                                             i < vehicleData.length;
    //                                             i++
    //                                         ) {
    //                                             balanceSheetLines.push({
    //                                                 SiteId: i + 1,
    //                                                 cashAtBankTotal: 0,
    //                                                 newVehiclesTotal:
    //                                                     vehicleData[i][0],
    //                                                 newVehiclesData:
    //                                                     vehicleData[i][1],
    //                                                 usedVehiclesTotal:
    //                                                     vehicleData[i][2],
    //                                                 usedVehiclesData:
    //                                                     vehicleData[i][3],
    //                                                 floorplanLiabilityTotal:
    //                                                     floorplanLiability[
    //                                                         i
    //                                                     ][0],
    //                                                 floorplanLiabilityData:
    //                                                     floorplanLiability[
    //                                                         i
    //                                                     ][1],
    //                                                 stockAdjustmentTotal: 0,
    //                                                 stockAdjustmentData: [],
    //                                                 averageCostAdjustmentTotal: 0,
    //                                                 averageCostAdjustmentData:
    //                                                     [],
    //                                                 stripeFeesTotal: 0,
    //                                                 stripeFeesData: [],
    //                                                 rebateBalanceTotal: 0,
    //                                                 rebateBalanceData: [],
    //                                                 registrationClearingTotal: 0,
    //                                                 registrationClearingData:
    //                                                     [],
    //                                                 accountLines: accounts[i],
    //                                                 cashHoldingTotal: 0,
    //                                                 stockOnHandTotal:
    //                                                     inventoryOther[i][0],
    //                                                 pendingDebtorPayment:
    //                                                     debtorPayment[i][0],
    //                                                 debtorPaymentData:
    //                                                     debtorPayment[i][1],
    //                                                 giftVoucherData: [],
    //                                                 giftVoucher: 0,
    //                                                 pendingPayrunData: [],
    //                                                 pendingPayrun: 0,
    //                                                 paymentLiabilityPA:
    //                                                     customerDeposit[i][0],
    //                                                 paymentLiabilityVehicleSale:
    //                                                     customerDeposit[i][1],
    //                                                 paymentLiabilityService:
    //                                                     customerDeposit[i][2],
    //                                                 paymentLiabilityDataPA:
    //                                                     customerDeposit[i][3],
    //                                                 paymentLiabilityDataVehicleSale:
    //                                                     customerDeposit[i][4],
    //                                                 paymentLiabilityDataService:
    //                                                     customerDeposit[i][5]
    //                                             });
    //                                             if (
    //                                                 i ===
    //                                                 vehicleData.length - 1
    //                                             ) {
    //                                                 balanceSheetLines[
    //                                                     i
    //                                                 ].pendingPayrunData =
    //                                                     pendingPayrun[1];
    //                                                 balanceSheetLines[
    //                                                     i
    //                                                 ].pendingPayrun =
    //                                                     pendingPayrun[0];
    //                                                 balanceSheetLines[
    //                                                     i
    //                                                 ].giftVoucherData =
    //                                                     giftVoucher[1];
    //                                                 balanceSheetLines[
    //                                                     i
    //                                                 ].giftVoucher =
    //                                                     giftVoucher[0];
    //                                             }
    //                                         }
    //                                         setShowSiteSelector(true);
    //                                         setLoading(false);
    //                                         setAllLines(balanceSheetLines);

    //                                         CombineBalanceSheetLines(
    //                                             balanceSheetLines,
    //                                             setSingleLine
    //                                         );
    //                                     });
    //                                 });
    //                             });
    //                         });
    //                     });
    //                 });
    //             }
    //         );
    //     }
    // );
};

export default CalculateBalanceSheet;

import api from '../../../../../../api';
import CalculateStockOrderTotalCost from '../CalculateStockOrderTotalCost';

import { Dispatch, SetStateAction } from 'react';
import { showSnackbar } from '../../../../global/interfaces/GlobalInterface';
import { Api } from '../../../../global/interfaces/GeneralInterface';
interface Table {
    OrderType: string;
    orderLines: TableOrderLine[];
    orderReference: string;
    supplierId: number;
    supplierName: string;
    tableId: number;
    totalCost: number;
}

interface TableOrderLine {
    OrderType: string; // probably not useful
    costPrice: number;
    gst: number;
    id: number;
    maxStock: number; // probably not used anymore
    minStock: number; // probably not used anymore
    name: string;
    partNumber: string;
    qtyOnHand: number;
    quantity: number;
    rowId: number;
    rowTotal: number;
    rrp: number;
    selected: boolean;
    supplier: any; // don't know what it's for

    linkedOrders?: number[];
    linkedOrdersQuantity?: number[];
    orderId?: number;
    costPriceDaily?: number;
    costPriceAverage?: number;
    supplierName?: string;
}

const GeneratePeterStevensOrderFile = (
    obj: { generator: Api; table: Table },
    tables: Table[],
    setTables: Dispatch<SetStateAction<Table[]>>,
    showSnackbar: showSnackbar
) => {
    let table = obj.table;

    let data: [string, string, string | number, string, string][] = [
        ['PO', 'Part#', 'Qty', 'Binloc', 'Notes']
    ];
    for (let line of table.orderLines) {
        data.push([
            'PO' + table.orderReference,
            line.partNumber,
            line.quantity,
            '',
            ''
        ]);
    }

    let csvContent = data.map((rowArray) => rowArray.join(',')).join('\r\n'); //transform an array of arrays into a CSV string
    let blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' }); //create a Blob from the CSV content
    let url = URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'test.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
    URL.revokeObjectURL(url); // Free up memory

    // Remove the generated order, so that the user can still submit the others orders without redoing the whole stock order
    let newTables = [];
    let orderedTables = [];
    tables.forEach((orderTable) => {
        if (
            orderTable.supplierName !== table.supplierName ||
            orderTable.OrderType !== table.OrderType
        ) {
            newTables.push(table);
        } else {
            orderedTables.push(table);
        }
    });
    setTables(newTables);

    // Send the Stock Order to the database
    var postObj = {
        tables: orderedTables,
        totalCost: CalculateStockOrderTotalCost(orderedTables).toFixed(2),
        SiteId: localStorage.getItem('SiteId'),
        generated: false,
        txtGenerated: true
    };

    api.post(`stockorder?emailed=false`, postObj).then((res) => {
        if (res.status === 200) {
            showSnackbar('Order .txt file generated');
        } else {
            showSnackbar(
                'Order not submitted. Please check your data or try again later.',
                '',
                'error'
            );
        }
    });
};

export default GeneratePeterStevensOrderFile;

import { MenuItem, TextField, Typography, useTheme } from '@mui/material';
import RoleCheck from '../../RoleCheck';
import DataCell from '../../tableComponents/DataCell';
import { PaymentLine } from '../../interfaces/GeneralInterface';
import { Dispatch, SetStateAction } from 'react';
import { Order } from '../../interfaces/PartsInterface';
import { VehicleSale } from '../../interfaces/VehicleSaleInterface';
import { Service } from '../../interfaces/ServiceInterface';
import { showSnackbar } from '../../interfaces/GlobalInterface';
import QuickSaleUpdate from '../../../parts/newSale/logic/updateFunctions/QuickSaleUpdate';

interface TillProps {
    payment: PaymentLine;
    paymentLines: PaymentLine[];
    setPaymentLines: Dispatch<SetStateAction<PaymentLine[]>>;
    saleDetails: Order;
    vehicleSaleDetails: VehicleSale;
    serviceDetails: Service;
    showSnackbar: showSnackbar;
    disabled: boolean;
    tills: any[];
    isPaymentTillReconciled: boolean;
    isFinalised: boolean;
}

const Till = ({
    payment,
    tills,
    paymentLines,
    setPaymentLines,
    saleDetails,
    vehicleSaleDetails,
    serviceDetails,
    showSnackbar,
    disabled,
    isPaymentTillReconciled,
    isFinalised
}: TillProps) => {
    let currentMode = useTheme().palette.mode;

    // Update a payment type
    const updateTill = (id: number, value: number) => {
        var index = paymentLines.findIndex((x) => x.id === id);
        let rows = [...paymentLines];
        let row = { ...rows[index] };

        row.TillId = value;
        rows[index] = row;
        setPaymentLines(rows);

        if (saleDetails?.id && saleDetails?.status === 'Sale') {
            QuickSaleUpdate(saleDetails.id, rows, showSnackbar, 'part');
        } else if (
            vehicleSaleDetails?.id &&
            vehicleSaleDetails?.saleStatus === 'Finalised'
        ) {
            QuickSaleUpdate(
                vehicleSaleDetails.id,
                rows,
                showSnackbar,
                'vehicle'
            );
        } else if (
            serviceDetails?.id &&
            serviceDetails?.serviceComplete === 'Finalised'
        ) {
            QuickSaleUpdate(serviceDetails.id, rows, showSnackbar, 'service');
        }
    };

    return (
        <DataCell textDecoration={payment.removed ? 'line-through' : ''}>
            {disabled ||
            payment.reconciled ||
            payment.inactive ||
            payment.removed ? (
                <Typography
                    variant="body2"
                    sx={{ color: payment.inactive ? '#808080' : '' }}
                >
                    {tills.find((till) => till.id === payment.TillId)?.Site
                        .name ?? ''}
                    <br />
                    {tills.find((till) => till.id === payment.TillId)?.name ??
                        ''}
                </Typography>
            ) : isPaymentTillReconciled ? (
                <RoleCheck
                    permission="admin_manager"
                    component={
                        <TextField
                            variant="standard"
                            size="small"
                            fullWidth
                            select
                            value={payment.TillId}
                            onChange={(e) =>
                                updateTill(payment.id, parseInt(e.target.value))
                            }
                        >
                            {tills.map((till) => (
                                <MenuItem key={till.id} value={till.id}>
                                    <Typography
                                        sx={{ display: 'flex' }}
                                        textAlign="center"
                                    >
                                        <span
                                            style={{
                                                color:
                                                    currentMode === 'dark'
                                                        ? '#fff'
                                                        : '#000'
                                            }}
                                        >
                                            {till.name}
                                        </span>
                                        &nbsp;|&nbsp;
                                        <span
                                            style={{
                                                color:
                                                    currentMode === 'dark'
                                                        ? '#888'
                                                        : '#555',
                                                fontSize: '14px'
                                            }}
                                        >
                                            {till.Site?.name}
                                        </span>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    }
                    nullComponent={
                        <Typography
                            variant="body2"
                            sx={{ color: payment.inactive ? '#808080' : '' }}
                        >
                            {tills.find((till) => till.id === payment.TillId)
                                ?.Site.name ?? ''}
                            <br />
                            {tills.find((till) => till.id === payment.TillId)
                                ?.name ?? ''}
                        </Typography>
                    }
                />
            ) : isFinalised ? (
                <RoleCheck
                    permission="edit_salePayment"
                    component={
                        <TextField
                            variant="standard"
                            size="small"
                            fullWidth
                            select
                            value={payment.TillId}
                            onChange={(e) =>
                                updateTill(payment.id, parseInt(e.target.value))
                            }
                        >
                            {tills.map((till) => (
                                <MenuItem key={till.id} value={till.id}>
                                    <Typography
                                        sx={{ display: 'flex' }}
                                        textAlign="center"
                                    >
                                        <span
                                            style={{
                                                color:
                                                    currentMode === 'dark'
                                                        ? '#fff'
                                                        : '#000'
                                            }}
                                        >
                                            {till.name}
                                        </span>
                                        &nbsp;|&nbsp;
                                        <span
                                            style={{
                                                color:
                                                    currentMode === 'dark'
                                                        ? '#888'
                                                        : '#555',
                                                fontSize: '14px'
                                            }}
                                        >
                                            {till.Site?.name}
                                        </span>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    }
                    nullComponent={
                        <Typography
                            variant="body2"
                            sx={{ color: payment.inactive ? '#808080' : '' }}
                        >
                            {tills.find((till) => till.id === payment.TillId)
                                ?.Site.name ?? ''}
                            <br />
                            {tills.find((till) => till.id === payment.TillId)
                                ?.name ?? ''}
                        </Typography>
                    }
                />
            ) : (
                <TextField
                    variant="standard"
                    size="small"
                    fullWidth
                    select
                    value={payment.TillId}
                    onChange={(e) =>
                        updateTill(payment.id, parseInt(e.target.value))
                    }
                >
                    {tills.map((till) => (
                        <MenuItem key={till.id} value={till.id}>
                            <Typography
                                sx={{ display: 'flex' }}
                                textAlign="center"
                            >
                                <span
                                    style={{
                                        color:
                                            currentMode === 'dark'
                                                ? '#fff'
                                                : '#000'
                                    }}
                                >
                                    {till.name}
                                </span>
                                &nbsp;|&nbsp;
                                <span
                                    style={{
                                        color:
                                            currentMode === 'dark'
                                                ? '#888'
                                                : '#555',
                                        fontSize: '14px'
                                    }}
                                >
                                    {till.Site?.name}
                                </span>
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            )}
        </DataCell>
    );
};

export default Till;
// LOGIC
import ValidateService from './ValidateService';
import api from '../../../../../api';
// INTERFACES
import { Customer, User } from '../../../global/interfaces/GeneralInterface';
import {
    CustomerVehicle,
    PurchaseOrder,
    Service
} from '../../../global/interfaces/ServiceInterface';
import { Vehicle } from '../../../global/interfaces/VehicleSaleInterface';
import { showSnackbar } from '../../../global/interfaces/GlobalInterface';
import { Order } from '../../../global/interfaces/PartsInterface';

const HandleSubmitService = (
    selectedCustomer: Customer,
    selectedInsurer: Customer,
    vehicleType: string,
    selectedVehicle: Vehicle | CustomerVehicle,
    selectedTech: User,
    serviceDetails: Service,
    partSales: Order[],
    sublets: PurchaseOrder[],
    showSnackbar: showSnackbar
) => {
    let validated = ValidateService(
        selectedCustomer,
        selectedInsurer,
        vehicleType,
        selectedVehicle,
        serviceDetails,
        partSales,
        sublets,
        showSnackbar
    );

    if (validated) {
        let obj = {
            selectedCustomer: selectedCustomer,
            selectedInsurer: selectedInsurer,
            vehicleType: vehicleType,
            selectedVehicle: selectedVehicle,
            selectedTech: selectedTech,
            serviceDetails: serviceDetails
        };
        api.post(
            `createNewService?SiteId=${localStorage.getItem('SiteId')}`,
            obj
        )
            .then((res) => {
                if (res.status === 200) {
                    const { newServiceId, proformaSaleStatus } = res.data;

                    if (proformaSaleStatus === 'success') {
                        showSnackbar(
                            'Service and associated parts sale created successfully.',
                            'The page will now redirect you to the booking details page.'
                        );
                    } else if (proformaSaleStatus === 'failed') {
                        showSnackbar(
                            'Service submitted successfully. Associated parts sale submit failed',
                            'Please create a parts sale for the new service',
                            'warning'
                        );
                    } else {
                        showSnackbar(
                            'Service submitted successfully.',
                            'The page will now redirect you to the booking details page.'
                        );
                    }

                    setTimeout(() => {
                        window.location.href =
                            '/service/viewBooking/' + newServiceId;
                    }, 2000);
                } else {
                    showSnackbar(
                        'Whoops! Something went wrong on our end.',
                        'Please contact your IT department.',
                        'error'
                    );
                }
            })
            .catch((error) => {
                showSnackbar(
                    'Whoops! Something went wrong on our end.',
                    'Please contact your IT department.',
                    'error'
                );
            });
    }
};

export default HandleSubmitService;

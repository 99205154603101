// IMPORTS
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import RoleCheck from '../global/RoleCheck';

// DASHBOARD
import ServiceDashboard from '../service/dashboard/ServiceDashboard';

// BOOKINGS
import BookingTable from '../service/bookingTable/BookingTable';
import NewBooking from '../service/newBooking/NewBooking';
import ServicePayment from '../service/servicePayment/ServicePayment';

// CODES
import ServiceCodeTable from '../service/serviceCodes/ServiceCodeTable';
import ViewServiceCode from '../service/serviceCodes/ViewServiceCode';

// MISC
import PurchaseOrderTable from '../service/purchaseOrders/PurchaseOrderTable';

// CALENDAR
import ServiceCalendarRBC from '../service/newCalendar/ServiceCalendarRBC';
import ServiceCalendarRBCDarkMode from '../service/newCalendar/ServiceCalendarRBCDarkMode';
import { useTheme } from '@mui/material';

const ServiceRouter = () => {
    let { path } = useRouteMatch();
    const currentTheme = useTheme();
    return (
        <Switch>
            {/* BOOKINGS */}
            <Route path={`${path}/bookings`}>
                <BookingTable />
            </Route>
            <Route path={`${path}/newBooking`} key={new Date().getTime()}>
                <NewBooking />
            </Route>
            <Route path={`${path}/viewBooking/:id`}>
                <NewBooking />
            </Route>
            <Route path={`${path}/payment/:id`}>
                <ServicePayment />
            </Route>
            <Route path={`${path}/payment`}>
                <ServicePayment />
            </Route>

            {/* CODES */}
            <Route path={`${path}/codes/add`}>
                <RoleCheck
                    component={<ViewServiceCode />}
                    permission={'access_jobCode'}
                    pageError
                />
            </Route>
            <Route path={`${path}/codes/view/:id`}>
                <RoleCheck
                    component={<ViewServiceCode />}
                    permission={'access_jobCode'}
                    pageError
                />
            </Route>
            <Route path={`${path}/codes`}>
                <RoleCheck
                    component={<ServiceCodeTable />}
                    permission={'access_jobCode'}
                    pageError
                />
            </Route>

            {/* MISC */}
            <Route path={`${path}/purchaseOrders`}>
                <PurchaseOrderTable />
            </Route>

            {/* BOOKING CALENDAR */}
            <Route path={`${path}/calendar`}>
                {currentTheme.palette.mode === 'dark' ? (
                    <ServiceCalendarRBCDarkMode />
                ) : (
                    <ServiceCalendarRBC />
                )}
            </Route>

            {/* Service DASHBOARD */}
            <Route path={`${path}/dashboard`}>
                <ServiceDashboard />
            </Route>
        </Switch>
    );
};

export default ServiceRouter;

// IMPORTS
import { useState, useEffect } from 'react';
import {
    Divider,
    Grid,
    IconButton,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import { useParams, Prompt } from 'react-router-dom';
// COMPONENTS
import Fab from '../../global/Fab';
import Paper from '../../global/Paper';
import PageWrapper from '../../global/PageWrapper';
import { withSnackbar } from '../../global/WrappingSnackbar';
// LOGIC
import GetServiceCode from './logic/GetServiceCode';
import HandleEditServiceCode from './logic/HandleEditServiceCode';
import HandleAddServiceCode from './logic/HandleAddServiceCode';
// INTERFACES
import { showSnackbar } from '../../global/interfaces/GlobalInterface';
import { ServiceCode } from '../../global/interfaces/ServiceInterface';
import PartSearch from '../../global/PartSearch';
import DataTable from '../../global/tableComponents/DataTable';
import DataCellColoured from '../../global/tableComponents/DataCellColoured';
import DataCell from '../../global/tableComponents/DataCell';
import { Close } from '@mui/icons-material';
import HandleAddPresetPart from './logic/HandleAddPresetPart';

const ViewServiceCode = ({ showSnackbar }: { showSnackbar: showSnackbar }) => {
    const { id } = useParams<{ id: string }>();
    const SiteId = Number(localStorage.getItem('SiteId'));
    const [shouldBlockNavigation, setShouldBlockNavigation] =
        useState<boolean>(false);

    const [readOnly, setReadOnly] = useState<boolean>(true);
    const [serviceCode, setServiceCode] = useState<ServiceCode>({
        serviceCode: null,
        servicePrice: null,
        jobCardDescription: null,
        invoiceDescription: null,
        serviceDuration: null,
        presetParts: [],
        SiteId: SiteId
    });

    useEffect(() => {
        if (id) {
            GetServiceCode(parseInt(id), setServiceCode);
        } else {
            setReadOnly(false);
        }
        // eslint-disable-next-line
    }, [id]);

    const handleSubmit = () => {
        if (id) {
            if (readOnly) {
                setReadOnly(false);
                setShouldBlockNavigation(true);
            } else {
                HandleEditServiceCode(parseInt(id), serviceCode, showSnackbar);
            }
        } else {
            HandleAddServiceCode(serviceCode, showSnackbar);
        }
    };

    const handleAddPart = (selectedPart, supplierData) => {
        HandleAddPresetPart(
            selectedPart,
            supplierData,
            serviceCode,
            setServiceCode
        );
    };

    const partColumns = [
        { id: 0, label: 'Part Number', width: 100 },
        { id: 1, label: 'Name', width: 200 },
        { id: 2, label: 'Qty', width: 100 },
        { id: 3, label: 'Supplier', width: 50 },
        ...(readOnly ? [] : [{ id: 4, label: '', width: 50 }])
    ];

    const handleEditRow = (index, key, value) => {
        let newPresetParts = [...serviceCode.presetParts];
        newPresetParts[index][key] = value;
        setServiceCode({ ...serviceCode, presetParts: newPresetParts });
    };

    const handleRemoveRow = (id) => {
        let newPresetParts = serviceCode.presetParts.filter(
            (part) => part.id !== id
        );
        setServiceCode({ ...serviceCode, presetParts: newPresetParts });
    };

    return (
        <PageWrapper>
            <Prompt
                when={shouldBlockNavigation}
                message="You have unsaved changes, are you sure you want to leave?"
            />
            <Typography variant="h4" align="left">
                {id ? serviceCode?.serviceCode : 'New Service Code'}
            </Typography>
            <br />

            <Paper>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            disabled={readOnly}
                            label="Service Code"
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.serviceCode}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    serviceCode: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            disabled={readOnly}
                            label="Labour Charge"
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.servicePrice}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    servicePrice: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            fullWidth
                            type="number"
                            disabled={readOnly}
                            label="Labour Duration"
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.serviceDuration}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    serviceDuration: parseFloat(e.target.value)
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            multiline
                            rows={15}
                            disabled={readOnly}
                            label="Job Card Description"
                            helperText="This is the text that will be prefilled into the job card."
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.jobCardDescription}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    jobCardDescription: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            multiline
                            rows={15}
                            disabled={readOnly}
                            label="Customer Invoice Description"
                            helperText="This is the text that will be prefilled into the customer invoice."
                            InputLabelProps={{ shrink: true }}
                            value={serviceCode?.invoiceDescription}
                            onChange={(e) =>
                                setServiceCode({
                                    ...serviceCode,
                                    invoiceDescription: e.target.value
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={6}>
                            <PartSearch
                                HandleAdd={handleAddPart}
                                size="small"
                                isDisabled={readOnly}
                            />
                        </Grid>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h6"
                                    color={readOnly ? 'text.disabled' : ''}
                                >
                                    Preset Parts
                                </Typography>
                            </Grid>
                        </Grid>
                        <DataTable columns={partColumns}>
                            <>
                                {serviceCode?.presetParts?.map(
                                    (line, index) => {
                                        return (
                                            <TableRow>
                                                <DataCellColoured
                                                    handleClick={() =>
                                                        window.open(
                                                            `/inventory/view/${line.UnitId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {line.partNumber}
                                                </DataCellColoured>
                                                {line.name.length > 15 ? (
                                                    <Tooltip title={line.name}>
                                                        <DataCell>
                                                            {line.name.slice(
                                                                0,
                                                                15
                                                            )}
                                                            ...
                                                        </DataCell>
                                                    </Tooltip>
                                                ) : (
                                                    <DataCell>
                                                        {line.name}
                                                    </DataCell>
                                                )}
                                                <DataCell>
                                                    {readOnly ? (
                                                        line.quantity
                                                    ) : (
                                                        <TextField
                                                            type="number"
                                                            variant="standard"
                                                            size="small"
                                                            disabled={readOnly}
                                                            fullWidth
                                                            inputProps={{
                                                                style: {
                                                                    textAlign:
                                                                        'center'
                                                                }
                                                            }}
                                                            value={
                                                                line.quantity
                                                            }
                                                            onChange={(e) =>
                                                                handleEditRow(
                                                                    index,
                                                                    'quantity',
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </DataCell>
                                                <DataCellColoured
                                                    handleClick={() =>
                                                        window.open(
                                                            `/viewCreditor/${line.SupplierId}`,
                                                            '_blank'
                                                        )
                                                    }
                                                >
                                                    {line.SupplierId}
                                                </DataCellColoured>
                                                {!readOnly && (
                                                    <DataCell>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleRemoveRow(
                                                                    line.id
                                                                )
                                                            }
                                                        >
                                                            <Close />
                                                        </IconButton>
                                                    </DataCell>
                                                )}
                                            </TableRow>
                                        );
                                    }
                                )}
                            </>
                        </DataTable>
                    </Grid>
                </Grid>
            </Paper>

            <Fab editIcon={readOnly} onClick={() => handleSubmit()} />
        </PageWrapper>
    );
};

export default withSnackbar(ViewServiceCode);
